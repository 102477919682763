import { Typography, Box } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const QuestionComponent = ({ title, button, description, content, loading, data, onClose, children, reason }) => {
  const [showReason, setShowReason] = useState();

  console.log('* * * QuestionComponent', { data, button });
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '550px',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        px: 3,
        py: 2,
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
        }}
      >
        {t(title)}
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          my: 3,
          overflow: 'unset',
        }}
      >
        {description ? <DialogContentText>{t(description)}</DialogContentText> : ''}
        {content ? <DialogContentText>{content}</DialogContentText> : ''}
        {children ? <DialogContentText>{children}</DialogContentText> : ''}
      </DialogContent>
      {button?.reject || button?.confirm ? (
        <DialogActions
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {button?.reject ? (
            <LoadingButton
              fullWidth={button?.confirm}
              type="click"
              variant="outlined"
              disabled={loading || button?.reject?.loading}
              color="success"
              onClick={(e) => button?.reject?.onClick(e, data)}
            >
              {button?.reject?.label ? t(button?.reject?.label) : 'لغو'}
            </LoadingButton>
          ) : (
            ''
          )}

          {button?.confirm ? (
            <LoadingButton
              fullWidth={button?.reject}
              type="click"
              variant="contained"
              color="success"
              loading={loading || button?.confirm?.loading}
              onClick={(e) => button?.confirm?.onClick(e, data)}
            >
              {button?.confirm?.label ? t(button?.confirm?.label) : 'تایید'}
            </LoadingButton>
          ) : (
            ''
          )}
        </DialogActions>
      ) : (
        ''
      )}
    </Box>
  );
};

export default QuestionComponent;
