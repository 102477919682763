import Number from './Number';
import { formTypesObject } from '../../../../../enumeration';
import FormTypeSelect from './Select';
import FormTypeSocial from './Social';
import FormTypeLocation from './Location';

const FormByType = ({ type }) => {
  const componentByTypes = {
    [formTypesObject.NUMBER.value]: <Number />,
    [formTypesObject.SELECT.value]: <FormTypeSelect />,
    [formTypesObject.RADIO.value]: <FormTypeSelect />,
    [formTypesObject.CHECKBOX.value]: <FormTypeSelect />,
    [formTypesObject.SOCIAL_MEDIA.value]: <FormTypeSocial />,
    [formTypesObject.LOCATION.value]: <FormTypeLocation />,
  };

  // console.log('* * * FormByType : ', { type, componentByTypes: componentByTypes[type?.value] });
  return type ? componentByTypes[type?.value] || <></> : <></>;
  //   return <></>;
};

export default FormByType;
