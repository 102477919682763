import ToastStyle from './ToastStyle';

const GlobalStyles = () => {
  return (
    <>
      <ToastStyle />
    </>
  );
};

export default GlobalStyles;
