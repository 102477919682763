import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';
import UniversitySelector from '../university/selector';
import universityCategorySelector from '../universityCategory/selector';

export const optionsSelect = [
  {
    label: 'پیش فرض',
    value: false,
  },
  {
    label: 'نوع دانشگاه',
    value: 'isUniversityCategory',
    isUniversityCategory: true,
    selector: universityCategorySelector,
  },
  {
    label: 'دانشگاه',
    value: 'isUniversity',
    isUniversity: true,
    selector: UniversitySelector,
  },
];

const caravanTabType = {
  specification: { label: 'مشخصات', value: 'specification' },
  travels: { label: 'سفر ها', value: 'travels' },
  access: { label: 'دسترسی ها', value: 'access' },
  message: { label: 'پیام ها', value: 'message' },
  historyRegistration: { label: 'سابقه ثبت نام ها', value: 'history-registration' },
  nezam: { label: 'نظام وظیفه', value: 'nezam' },
  bank: { label: 'بانک', value: 'bank' },
  // social: { label: 'شبکه اجتماعی', value: 'social' },
  // tutorial: { label: 'آموزش و آزمون', value: 'tutorial' },
  // more: { label: 'سایر امکانات', value: 'more' },
};

const EProfile = {
  title: {
    name: ['پروفایل', 'پروفایل ها'],
  },
  routes: PATH_DASHBOARD.profile,
  api: api.client,
  bseName: 'a_',

  // enum: {
  //   array: Object.values(caravanTabType),
  //   object: caravanTabType,
  // },
  enumTab: {
    array: Object.values(caravanTabType),
    object: caravanTabType,
  },
};

export default EProfile;
