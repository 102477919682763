import { travelTypeObject } from '../../../../enumeration';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import api from '../../../../services/api';

const ENotification = {
  title: {
    name: ['پیام', 'پیام ها'],
  },
  routes: PATH_DASHBOARD.profile,
  api: api.notification,
};

export default ENotification;
