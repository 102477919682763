import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD, PUBLIC_PATH_DASHBOARD } from '../routes/paths';
import useAuth from '../hooks/useAuth';

const RouterMiddleware = ({ children }) => {
  const { isAuthenticated, isInitialized } = useAuth();
  const accessToken = window.localStorage.getItem('accessToken');

  // const admin = useSelector((store) => store.admin);
  const { pathname } = useLocation();
  console.log({ pathname, isAuthenticated });
  if (pathname === '/') {
    if (isAuthenticated || accessToken) {
      return <Navigate to={PATH_DASHBOARD.root} />;
    }
    // return <Navigate to={PUBLIC_PATH_DASHBOARD.root} />;
  }
  return <>{children}</>;
};

export default RouterMiddleware;
