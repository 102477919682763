import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const ESocial = {
  title: {
    name: ['سوشیال', 'سوشیال ها'],
  },
  // routes: PATH_DASHBOARD.social,
  api: api.social,
  // enum: {
  //   array: Object.values(travelTypeObject),
  //   object: travelTypeObject,
  // },
};

export default ESocial;
