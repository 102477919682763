import { Route, Routes } from 'react-router';
import ById from './ById';
import Enum from './enum';
import List from './list';

const FormsComponent = () => {
  return (
    <Routes>
      <Route path={Enum.routes.byId} element={<ById />} />
      <Route path={`${Enum.routes.byId}/:sample`} element={<ById />} />
      <Route path={Enum.routes.list} element={<List />} />
    </Routes>
  );
};

export default FormsComponent;
