import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EVisitorManagement = {
  title: { name: ['نظام وظیفه', 'نظام وظیفه'] },
  routes: PATH_DASHBOARD.dutySystemManagement,
  api: api.travelRegister,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default EVisitorManagement;
