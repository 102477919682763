import { Box, Grid } from '@mui/material';
import validation from '../validation';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';

const FormTypeNumber = () => {
  return (
    <Box>
      <Box
        sx={{
          borderTop: '1px solid',
          borderColor: 'grey.200',
          mt: 1,
          mb: 3,
        }}
      />
      <Grid spacing={3} container>
        <Grid item md={6} xs={12}>
          <RHFTextField type={'number'} name={validation.fieldNames.min} label={'حداقل عدد'} />
        </Grid>
        <Grid item md={6} xs={12}>
          <RHFTextField type={'number'} name={validation.fieldNames.max} label={'حداکثر عدد'} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FormTypeNumber;
