import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    file: 'file',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.file]: yup.mixed().nullable().required(errorsText.blankError()),
    }),
};

export default validation;
