/* eslint-disable */
import { createPortal } from 'react-dom';
import { Box, Button, MenuItem, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import api from 'src/services/api';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useParams } from 'react-router-dom';
import axiosInstance from 'src/utils/axios';
import { useTranslation } from 'react-i18next';
import { useMutationCustom } from 'src/utils/reactQueryHooks';
import ModalLayout from '../../../../../../../components/ModalLayout';
import QuestionComponent from '../../../../../../../components/ui/Question/index';
import Reason from './Reason';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';

const AcceptOrRejectWithQuestionsWithReason = ({
  icon,
  iconSx,
  label,
  isDelete,
  isEdit,
  sx,
  question,
  onClick,
  loading,
  disabled,
  id,
  confirm_status,
  reason,
  ...props
}) => {
  // console.log({ id });
  const queryParams = useParams();

  const { t } = useTranslation();
  const [showReason, setShowReason] = useState();
  const ModalLayoutRemoveInputRef = useRef();

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post('/admin/confirm_input', { ...params, client_input_id: id });
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    setShowReason();
    ModalLayoutRemoveInputRef.current.show(false);
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  const { isLoading, mutate } = useMutationCustom({
    url: creating,
    name: `${'/admin/confirm_input'}_update`,
    invalidQuery: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  const handleSubmitReject = (data) => {
    mutate({ ...data, confirm_status: 0 });
  };

  const handleSubmitAccept = () => {
    mutate({ confirm_status: 1 });
    ModalLayoutRemoveInputRef.current.show(false);
  };

  const handleQuestionModal = (params) => {
    ModalLayoutRemoveInputRef.current.show((p) => !p, params);
  };

  const handleClick = (key, e) => {
    // e.preventDefault();

    handleQuestionModal(questionsText[key]);
    setShowReason(false);
  };

  const questionsText = {
    reject: {
      title: 'question.reject.title',
      // description: 'question.reject.description',
      reason: {
        label: 'دلیل رد کردن',
      },
      button: {
        confirm: {
          label: 'question.reject.yes',
          onClick: () => setShowReason(true),
          loading: isLoading,
        },
        reject: {
          label: 'question.reject.no',
          onClick: () => handleQuestionModal(),
        },
      },
      // content:
    },
    accept: {
      title: 'آیا مطمئن به تایید این مورد هستید؟',
      // description: 'اگر تایید کنید دیگر قابل تغییر نمی باشد.آیا ادامه میدهید؟',
      button: {
        confirm: {
          label: 'بله ، تایید میکنم',
          onClick: handleSubmitAccept,
          loading: isLoading,
        },
        reject: {
          label: 'خیر',
          onClick: () => handleQuestionModal(),
          disabled: isLoading,
        },
      },
    },
    rejected: {
      title: `دلیل رد شدن!`,
      description: reason,
      button: {
        confirm: {
          label: 'بله ، متوجه شدم',
          onClick: () => handleQuestionModal(),
          loading: isLoading,
        },
        // reject: {
        //   label: 'خیر',
        //   onClick: () => handleQuestionModal(),
        // },
      },
    },
  };

  return (
    <Box
      onMouseDown={(event) => {
        event.stopPropagation();
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'grey.800',
        ...sx,
      }}
      {...props}
    >
      {createPortal(
        <ModalLayout ref={ModalLayoutRemoveInputRef}>
          {showReason ? (
            <Reason isLoading={isLoading} onSubmit={handleSubmitReject} onClose={() => handleQuestionModal()} />
          ) : (
            <QuestionComponent
              {...{
                loading,
              }}
              onClose={() => handleQuestionModal()}
            />
          )}
        </ModalLayout>,
        document.body
      )}
      {confirm_status === 2 ? (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          <LoadingButton
            onClick={(e) => handleClick('reject', e)}
            sx={{ cursor: 'pointer' }}
            variant="outlined"
            color="success"
            size="small"
            loading={isLoading}
          >
            {'رد کردن'}
          </LoadingButton>
          <LoadingButton
            onClick={(e) => handleClick('accept', e)}
            sx={{ cursor: 'pointer' }}
            variant="contained"
            color="success"
            size="small"
            loading={isLoading}
          >
            {'تایید'}
          </LoadingButton>
        </Box>
      ) : confirm_status === 1 ? (
        <LoadingButton
          sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
          variant="contained"
          color="success"
          size="small"
        >
          <DoneIcon fontSize="11px" />
          {'تایید شد'}
        </LoadingButton>
      ) : confirm_status === 0 ? (
        <Tooltip title={reason} arrow>
          <LoadingButton
            onClick={(e) => handleClick('rejected', e)}
            sx={{ cursor: 'pointer', display: 'flex', gap: 1 }}
            variant="contained"
            color="error"
            size="small"
          >
            <RemoveRedEyeIcon fontSize="11px" />

            {'رد شد'}
          </LoadingButton>
        </Tooltip>
      ) : (
        ''
      )}
    </Box>
  );
};

export default AcceptOrRejectWithQuestionsWithReason;
