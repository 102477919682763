import { Button, InputAdornment } from '@mui/material';
import AcceptOrRejectWithQuestionsWithReason from 'src/pages/profiles/Show/Travels/ById/Section/AcceptOrRejectWithQuestionsWithReason';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';

const FormInputString = ({ name, data, disabled, ...props }) => {
  return (
    <RHFTextField
      name={name}
      label={data?.label}
      disabled={disabled}
      {...props}
      InputProps={
        props?.need_confirm
          ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                </InputAdornment>
              ),
            }
          : ''
      }
    />
  );
};

export default FormInputString;
