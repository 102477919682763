import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import { useTranslation } from 'react-i18next';
import RoleSelector from 'src/pages/role/selector';
import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../components/WaitingBox/index';
import RHFTextareaField from '../../../components/hook-form/RHFTextareaField';
import RHFSelector from '../../../components/hook-form/RHFSelector';

const Add = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root}`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  // const onSuccess = (resData) => {
  //   console.log('* * * onSuccess', { resData });
  //   const resetData = {
  //     [validation.fieldNames.send_to]: resData?.data?.[validation.fieldNames.send_to] || '',
  //     [validation.fieldNames.message]: resData?.data?.[validation.fieldNames.message] || '',
  //     // [validation.fieldNames.social]: socialSelector.convertor.object(resData?.data?.social),
  //   };
  //   console.log('* * * onSuccess', { resetData });
  //   reset(resetData);
  // };
  // const dataById = useQueryCustom({
  //   name: `getById_${Enum?.api?.social}_${paramId}`,
  //   url: getById,
  //   onSuccess: onSuccess,
  //   enabled: !!paramId,
  // });
  const mobileRegex = /^(?:\+98|0)?9\d{9}$/;
  const watchStartDate = watch(validation.fieldNames.startDate);
  // console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    values[validation.fieldNames.send_to] = persianToEnglishNumber(values[validation.fieldNames.send_to]?.trim());
    const splitPhones = values[validation.fieldNames.send_to]?.split('\n');
    // console.log('* * * onSubmit : ', { values, splitPhones });
    const inValidPhones = [];
    let isValid = true;
    splitPhones?.forEach((x) => {
      if (x?.trim()) {
        const valid = mobileRegex.test(x);
        isValid = isValid && valid;
        if (!valid) inValidPhones.push(x);
      }
    });
    // console.log('* * * onSubmit : ', { isValid, inValidPhones });

    if (!isValid) {
      return setError(
        validation.fieldNames.send_to,
        { message: `${inValidPhones?.join(' - ')} شماره های نادرست ` },
        { shouldFocus: true }
      );
    }
    const reqData = {
      [validation.fieldNames.message]: values[validation.fieldNames.message],
      [validation.fieldNames.send_to]: splitPhones,
      team_id: queryParams.id,
    };
    // const validation
    console.log('* * * onSubmit : ', { reqData, values, splitPhones, isValid, inValidPhones });
    mutate(reqData);
  };
  console.log({ queryParams });

  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs
        heading={
          paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
        back={backUrl}
        // links={[
        //   { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
        //   { name: title, href: Enum.routes.list },
        //   { name: 'لیست' },
        // ]}
      />
      {/* <HeaderBreadcrumbs
        back={`${Enum.routes.root}`}
        heading={
          paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
      >
      </HeaderBreadcrumbs> */}
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.message} label={'متن پیام'} />
            </Grid>
            <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.send_to} label={'شماره های همراه'} />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'ثبت اطلاعات'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default Add;
