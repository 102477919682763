import { Cancel, Edit } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';

const SectionInput = ({ data, onEdit, onRemove }) => {
  console.log('* * * SectionInput : ', { data });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: 'grey.100',
        },
      }}
    >
      <Typography>{data?.label}</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
        }}
      >
        {data?.changeable ? (
          <Edit
            sx={{
              cursor: 'pointer',
              color: 'grey.500',
              '&:hover': {
                color: 'grey.700',
              },
            }}
            onClick={onEdit}
          />
        ) : (
          ''
        )}
        {data?.changeable ? (
          <Cancel
            sx={{
              cursor: 'pointer',
              color: 'grey.500',
              '&:hover': {
                color: 'grey.700',
              },
            }}
            onClick={onRemove}
          />
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default SectionInput;
