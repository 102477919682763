import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    name: 'name',
    status: 'status',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.name]: yup.string().required(errorsText.blankError()),
    }),
};

export default validation;
