import { Box, Button, Container, Modal, Typography } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import api from 'src/services/api';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import AddComponent from '../../Course/Add';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';
import { hasAccess } from '../../../permission/utiles';
import LotteryModalComponent from '../LotteryModalComponent';
import { travelTypeObject } from '../../../enumeration/index';
import VisitorFilters from '../component/Filters';
import ShowFilters from '../component/ShowFilters';

const List = ({ access }) => {
  const ModalLayoutQuestionInputRef = useRef();
  const queryParams = useParams();
  const withExcel = [travelTypeObject.omre.value, travelTypeObject.tamato.value].includes(queryParams.type);

  const [toggleModal, setToggleModal] = useState();
  const queryString = useQueryString({ sort: 'createdAt@desc' });
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const location = useLocation();
  const isRecord = queryParams.isRecord;
  const previous = location?.state;
  const { t } = useTranslation();

  const checkAccess = useMemo(() => {
    return {
      update: hasAccess(access?.update),
      delete: hasAccess(access?.delete),
      create: hasAccess(access?.create),
      confirm: hasAccess(access?.confirm),
      read: hasAccess(access?.read),
    };
  }, [access]);

  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams, previous });
  const handleQuestionModal = () => ModalLayoutQuestionInputRef.current.show((p) => !p);

  // ------------------------------------------------------- query lottery
  const onSuccessMutating = () => {
    ModalLayoutQuestionInputRef.current.show((p) => false);
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const postLottery = async (params) => axiosInstance.post(`${api?.lottery?.base}`, params);
  const lotteryMutation = useMutationCustom({
    url: postLottery,
    name: `${api?.lottery?.base}_post`,
    // invalidQuery: `${api?.lottery?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  const handleLottery = () => {
    // alert();
    lotteryMutation.mutate({
      travel_id: queryParams.travel,
    });
  };
  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${queryParams.travel}`,
    url: getTravel,
    params: { id: queryParams.travel },
  });
  // ------------------------------------------------------- query Get

  const getting = async () => {
    // axiosInstance.get(`${Enum?.api?.base}/${queryParams.travel}`, { params: { ...queryString, status: 1 } });
    const { filters, ...others } = queryString;
    let newFilters;
    if (filters) newFilters = JSON.parse(filters || '')?.map((x) => ({ input_id: x.input_id, value: x.value }));
    // console.log('* * * getting : ', { newFilters });
    return axiosInstance.get(`${Enum?.api?.base}/${queryParams.travel}`, {
      params: {
        ...others,
        // 'status[]': 2,
        ...(filters && { filters: JSON.stringify(newFilters) }),
      },
    });
  };
  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}_registrations`,
    url: getting,
    params: { ...queryString },
    enabled: checkAccess.read,
  });

  const handleToggleModal = () => setToggleModal((p) => !p);

  // const data = [
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  // ];

  const tableOptions = tableData({
    disabled: {
      update: !checkAccess?.update,
      delete: !checkAccess?.delete,
    },
  });

  const title = `مدیریت ${Enum.title.name[0]}`;
  console.log({ access, tableOptions, data, withExcel });

  const tab = [
    {
      label: 'ثبت نامی ها',
      value: 'registrations',
      active: true,
    },
    {
      label: 'تایید شده',
      value: '',
      active: false,
    },
  ];

  return (
    <Page title={title}>
      <Modal
        open={toggleModal}
        onClose={handleToggleModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 6,
        }}
      >
        <AddComponent onClose={handleToggleModal} />
      </Modal>

      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        {withExcel ? (
          <LotteryModalComponent />
        ) : (
          <QuestionComponent
            {...{
              loading: lotteryMutation?.isLoading,
              title: 'آیا از قرعه کشی مطمئن هستید؟',
              description: 'پس از تایید قرعه کشی انجام خواهد شد . آیا ادامه میدهید؟',
              button: {
                confirm: {
                  label: 'question.yesProcess',
                  onClick: handleLottery,
                },
                reject: {
                  label: 'question.no',
                  onClick: handleQuestionModal,
                },
              },
            }}
            // onClose={handleQuestionModal}
          />
        )}
      </ModalLayout>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isRecord ? resTravel?.data?.data?.name : title}
          back={
            previous?.pathname
              ? { pathname: previous?.pathname, search: new URLSearchParams(previous?.query || {}).toString() }
              : false
          }
          links={[
            { name: 'داشبورد', href: PATH_DASHBOARD.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            checkAccess?.confirm && !isRecord ? (
              <Box sx={{}}>
                <Button
                  onClick={handleQuestionModal}
                  variant="contained"
                  color="success"
                  // component={Link}
                  // to={baseUrl + Enum.routes.lottery}
                  endIcon={<BlurCircularIcon />}
                >
                  {'قرعه کشی'}
                </Button>
              </Box>
            ) : (
              ''
            )
          }
        >
          {isRecord ? <Typography sx={{ mt: 1 }}>{title}</Typography> : ''}
        </HeaderBreadcrumbs>
        <Box
          sx={{
            // display: 'flex',
            alignItems: 'center',
            gap: 2,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          {tab?.map((x) => {
            return (
              <Link to={`${baseUrl}/${x.value}`}>
                <Typography
                  sx={{
                    color: 'grey.800',
                    p: 1,
                    borderBottom: '2px solid transparent',
                    fontWeight: '300',
                    fontSize: '13px',

                    ...(x.active
                      ? {
                          borderColor: 'grey.800',
                          fontWeight: '900',
                        }
                      : {
                          '&:hover': {
                            color: 'grey.600',
                          },
                        }),
                  }}
                >
                  {x.label}
                </Typography>
              </Link>
            );
          })}
        </Box>
        <TableComponent
          active={{
            // selectable: true,
            rowNumber: true,
            filter: {
              component: <VisitorFilters />,
            },
          }}
          show={{
            filters: <ShowFilters {...{ queryString }} />,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
