import {
  Box,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFCheckbox } from '../../../../components/hook-form/RHFCheckbox';
import RowItem from './RowItem';

const DashboardTravels = ({ data, label, basename, ...props }) => {
  const { watch, getValues, setValue } = useFormContext();
  const values = watch('permissions');

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 150 }}>{label}</TableCell>
          <TableCell sx={{ width: 50 }}>{'همه'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'ایجاد کردن'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'دیدن'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'ویرایش'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'حذف'}</TableCell>
          <TableCell sx={{ width: 50 }}>{'تایید'}</TableCell>
        </TableRow>
      </TableHead>
      {
        <TableBody>
          {Object.values(data || {})?.map((x, i) => {
            return <RowItem key={x.label} x={x} basename={basename} {...props} />;
          })}
        </TableBody>
      }
    </Table>
  );
};

export default DashboardTravels;
