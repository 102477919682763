import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress } from '@mui/material';
import { ControlPoint } from '@mui/icons-material';

import axiosInstance from '../../../utils/axios';
import api from '../../../services/api';
import Enum from '../../Form/enum';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import Section from './Section';
import AddSection from './Section/Add';
import ModalLayout from '../../../components/ModalLayout';
import WaitingBox from '../../../components/WaitingBox/index';
import { PATH_DASHBOARD, PUBLIC_PATH_DASHBOARD } from '../../../routes/paths';
import CopyClipboard from '../../../components/CopyClipboard';
import { PUBLIC_DOMAIN_ADDRESS } from '../../../config';

const ById = () => {
  const { id, sample, ...urlParams } = useParams();
  const { themeStretch } = useSettings();

  const ModalSectionFormRef = useRef();
  const handleToggleSectionModal = () => ModalSectionFormRef.current.show((p) => !p);

  // const getting = async () =>
  //   axiosInstance.get(api.section.base, {
  //     params: {
  //       form_id: id,
  //     },
  //   });
  const getById = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(sample ? `${Enum.api.sampleForm}/${params?.id}` : `${Enum?.api?.base}/${params?.id}`);
  };

  const resQuery = useQueryCustom({
    name: `${Enum?.api?.base}_get_${id}`,
    url: getById,
    params: { id },
  });
  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  console.log({ resQuery, sample });
  // resQuery?.data?.data?.sections
  // resQuery?.data?.data?.travel
  return resQuery.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={resQuery?.data?.data?.name}>
      <ModalLayout ref={ModalSectionFormRef}>
        <AddSection />
      </ModalLayout>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <HeaderBreadcrumbs heading={resQuery?.data?.data?.name} /> */}
        <HeaderBreadcrumbs
          back={`${PATH_DASHBOARD.form.root(urlParams.type, urlParams.travel)}`}
          heading={sample ? resQuery?.data?.data?.name : resQuery?.data?.data?.travel?.name}
        >
          {!sample ? <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> : ''}
        </HeaderBreadcrumbs>

        {!sample ? (
          <Box mb={1}>
            <CopyClipboard
              label={'ادمین عزیز از این لینک برای قرار دادن در وبسایت برای ثبت نام استفاده کنید.'}
              value={`${PUBLIC_DOMAIN_ADDRESS}${PUBLIC_PATH_DASHBOARD.form.root(
                urlParams.type,
                urlParams.travel
              )}/${id}`}
            />
          </Box>
        ) : (
          ''
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
          }}
        >
          {resQuery?.data?.data?.sections?.map((x) => (
            <Section key={x._id} data={x} sample={sample} />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 5,
          }}
        >
          {!sample ? (
            <Button onClick={handleToggleSectionModal} variant="outlined" color="success">
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <Typography>{'افزودن دسته سوالات جدید'}</Typography>
                <ControlPoint />
              </Box>
            </Button>
          ) : (
            ''
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ById;
