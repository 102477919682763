import { cloneElement, isValidElement, useEffect, useMemo } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

import { Link, Navigate, NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from '../enum';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import axiosInstance from '../../../utils/axios';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import WaitingBox from '../../../components/WaitingBox/index';
import useQueryString from '../../../utils/useQueryString';
import AgentComponent from './Agent/List';
import ClientComponent from './Client/List';
import SocialComponent from './Social';
import SelectorComponent from '../../../components/hook-form/Selector';

const ShowCaravansManagementLayout = ({ children, showClientButton }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const queryString = useQueryString();
  const location = useLocation();
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const previousQuery = location?.state?.query;
  const params = new URLSearchParams(previousQuery || {});
  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}`;

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  const dataById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${paramId}`,
    url: getById,
    enabled: !!paramId,
  });

  const caravan = dataById?.data?.data;

  console.log({ dataById, location, previousQuery });
  const caravanTabType = Enum.enumTab.object;
  const tab = [
    {
      ...caravanTabType.client,
      // active: !queryParams.tab && true,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {showClientButton ? (
            <Button onClick={showClientButton?.onClick} variant="outlined" color="success">
              {showClientButton?.label}
            </Button>
          ) : (
            ''
          )}

          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}
          <Button
            variant="contained"
            color={'success'}
            component={Link}
            to={`${currentBase}/${caravanTabType.client.value}/add`}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {'کاروان بندی'}
          </Button>
        </Box>
      ),
    },
    {
      ...caravanTabType.agent,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}

          {/* <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
            {'افزودن کابر'}
          </Button> */}
        </Box>
      ),
    },
    {
      ...caravanTabType.nezam,
      active: false,
    },
    {
      ...caravanTabType.bank,
      active: false,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}

          {/* <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
            {'افزودن کابر'}
          </Button> */}

          {/* <Button variant="outlined" color="success">
            {'صدور کارت'}
          </Button> */}
        </Box>
      ),
    },
    {
      ...caravanTabType.message,
      active: false,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}
          <Button
            variant="contained"
            color={'success'}
            component={Link}
            to={`${currentBase}/${caravanTabType.message.value}/add`}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            {'ارسال پیام'}
          </Button>
        </Box>
      ),
    },
    {
      ...caravanTabType.social,
      active: false,
      // side: (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       gap: 2,
      //       ml: 'auto',
      //     }}
      //   >
      //     <Button
      //       variant="contained"
      //       color={'success'}
      //       component={Link}
      //       to={`${currentBase}/${caravanTabType.social.value}/add`}
      //       startIcon={<Iconify icon={'eva:plus-fill'} />}
      //     >
      //       {'افزودن'}
      //     </Button>
      //   </Box>
      // ),
      // side: (
      //   <Box
      //     sx={{
      //       display: 'flex',
      //       alignItems: 'center',
      //       gap: 2,
      //       ml: 'auto',
      //     }}
      //   >
      //     <Button variant="grey">
      //       <PrintIcon
      //         sx={{
      //           cursor: 'pointer',
      //         }}
      //       />
      //     </Button>

      //     <Button
      //       variant="contained"
      //       color={'success'}
      //       component={Link}
      //       to={`${currentBase}/${caravanTabType.social.value}/edit`}
      //       startIcon={<Iconify icon={'eva:plus-fill'} />}
      //     >
      //       {'ویرایش'}
      //     </Button>
      //   </Box>
      // ),
    },
    {
      ...caravanTabType.tutorial,
      active: false,
      side: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 'auto',
          }}
        >
          {/* <Button variant="grey">
            <PrintIcon
              sx={{
                cursor: 'pointer',
              }}
            />
          </Button> */}

          <Button variant="contained" color={'success'} startIcon={<Iconify icon={'eva:plus-fill'} />}>
            {'افزودن'}
          </Button>
        </Box>
      ),
    },
    {
      ...caravanTabType.more,
      active: false,
    },
  ];
  // const tabOptions=useMemo(()=>{
  //   return tab?.map((x) =>({label:}));
  // },[queryString])

  const selectedTab = useMemo(() => {
    return tab?.find((x) => queryParams.tab === x.value || x.active);
  }, [queryString]);

  // useEffect(()=>{
  //   // if(!queryString.type)
  // },[])
  console.log({ aaaaa: `${backUrl}/${'aaa'}/` });

  const title = caravan?.name || '';
  // `${caravan?.name || ''} - ${caravan?.province?.name || ''}`;
  // const title = ` ${Enum.title.name[0]} ${caravan?.id || ''} - ${caravan?.province?.name || ''}`;

  const appendedPropsToChildren = () => {
    if (isValidElement(children)) {
      return cloneElement(children, {
        caravan,
      });
    }
    return <>{children || ' '}</>;
  };

  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={`${title}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          back={`${backUrl}?${params.toString()}`}
          links={[
            { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
        />
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
          }}
        >
          <Box
            sx={{
              // display: 'flex',
              alignItems: 'center',
              gap: 2,
              display: { xs: 'none', md: 'flex' },
            }}
          >
            {tab?.map((x) => {
              return (
                <Link to={`${currentBase}/${x.value}`}>
                  <Typography
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '13px',

                      ...(queryParams.tab === x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                    {x.label}
                  </Typography>
                </Link>
              );
            })}
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              gap: 2,
              display: { md: 'none', xs: 'flex' },
            }}
          >
            <SelectorComponent
              sx={{
                width: '200px',
              }}
              options={tab}
              onChange={(x) => {
                console.log({ x });
                navigate(`${currentBase}/${x.value}`);
              }}
              value={selectedTab}
              isClearable={false}
            />
          </Box>

          <Box
            sx={{
              ml: 'auto',
            }}
          >
            {selectedTab?.side}
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
          }}
        >
          {/* {selectedTab?.component} */}

          {appendedPropsToChildren()}
        </Box>
      </Container>
    </Page>
  );
};

export default ShowCaravansManagementLayout;
