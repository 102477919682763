import { useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress, Alert } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { ControlPoint } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SelectorComponent from 'src/components/hook-form/Selector';
import { LoadingButton } from '@mui/lab';
import api from '../../../../../services/api';
import axiosInstance from '../../../../../utils/axios';
import Enum from '../../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import Page from '../../../../../components/Page';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import useSettings from '../../../../../hooks/useSettings';
import WaitingBox from '../../../../../components/WaitingBox/index';
import SectionPublic from './Section';
import FormProvider from '../../../../../components/hook-form/FormProvider';
import { formTypesObject } from '../../../../../enumeration';
import errorsText from '../../../../../utils/errorsText';
import validation from './validation';
import { PUBLIC_PATH_DASHBOARD } from '../../../../../routes/paths';
import persianToEnglishNumber from '../../../../../utils/persianToEnglishNumber';
import { fDateForApi } from '../../../../../utils/formatTime';
import ModalLayout from '../../../../../components/ModalLayout';
import QuestionComponent from '../../../../../components/ui/Question/index';
import SingleForm from './Form';
import Layout from '../../Layout';

const Travel = () => {
  const ModalLayoutQuestionInputRef = useRef();
  const queryParams = useParams();

  // const { travel } = queryParams;
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [validationState, setValidationState] = useState({});
  const [TravelApi, setTravelApi] = useState();
  const [currentTab, setCurrentTab] = useState();
  const backUrl = `${Enum.routes.root}`;
  const paramId = queryParams?.id;
  const currentBase = `${backUrl}/show/${paramId}/${Enum.enumTab.object.travels.value}`;
  // const ModalSectionFormRef = useRef();

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validation.schema(validationState)),
    mode: 'onChange',
    // mode: 'all',
    shouldUnregister: false,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  const values = getValues();
  console.log({ errors, values });
  // ------------------------------------------------------------------------------ modal ref

  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const getById = async () => axiosInstance.get(`${api.travelRegister.register.base}/${queryParams.course}`);

  const resQuery = useQueryCustom({
    name: `${api.travelRegister.register.base}_get_${queryParams.course}`,
    url: getById,
    params: { id: queryParams.course, client_id: queryParams.id },
    onSuccess: (resData) => {
      console.log({ resData });
      const x = resData?.data?.form?.[0];
      if (x) setCurrentTab({ label: x?.name, value: x?.id, data: x });
    },
  });

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  const tab = resQuery?.data?.data?.form?.map((x) => ({ label: x?.name, value: x?.id, data: x }));

  console.log({ currentTab, resQuery, TravelApi, tab });

  return (
    <Layout>
      <Box
        sx={
          {
            // py:3
          }
        }
      >
        {resQuery.isLoading ? (
          <WaitingBox />
        ) : resQuery.isError ? (
          ''
        ) : (
          <Page
            sx={{
              py: 3,
            }}
            // title={resQuery?.data?.data?.name}
          >
            <HeaderBreadcrumbs heading={resQuery?.data?.data?.form?.[0]?.travel?.name} back={`${currentBase}/`} />
            <Box
              sx={{
                // display: 'flex',
                alignItems: 'center',
                gap: 2,
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {tab?.map((x) => {
                return (
                  // <Link to={`${currentBase}/${queryParams.course}?tab=${x.value}`}>
                  <Typography
                    onClick={() => setCurrentTab(x)}
                    sx={{
                      color: 'grey.800',
                      p: 1,
                      borderBottom: '2px solid transparent',
                      fontWeight: '300',
                      fontSize: '15px',
                      cursor: 'pointer',
                      ...(+currentTab?.value === +x.value
                        ? {
                            borderColor: 'grey.800',
                            fontWeight: '900',
                          }
                        : {
                            '&:hover': {
                              color: 'grey.600',
                            },
                          }),
                    }}
                  >
                    {x.label}
                  </Typography>
                  // </Link>
                );
              })}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                gap: 2,
                display: { md: 'none', xs: 'flex' },
              }}
            >
              <SelectorComponent
                sx={{
                  width: '200px',
                }}
                options={tab}
                onChange={(x) => {
                  console.log({ x });
                  setCurrentTab(x);
                  // navigate(`${currentBase}/${x.value}`);
                }}
                value={currentTab}
                isClearable={false}
              />
            </Box>
            <Box>
              {
                // resQuery.isFetching
                false ? (
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <WaitingBox />
                  </Box>
                ) : currentTab?.data?.sections?.length ? (
                  <SingleForm {...{ data: { form: currentTab?.data, team: resQuery?.data?.data?.team } }} />
                ) : (
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                  </Box>
                )
              }
            </Box>
            {/* {resQuery?.data?.data?.form?.map((x, i) => (
              <Box>
                <SingleForm {...{ data: { form: x, team: resQuery?.data?.data?.team }, index: i }} />
              </Box>
            ))} */}
          </Page>
        )}
      </Box>
    </Layout>
  );
};

export default Travel;
