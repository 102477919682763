import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    name: 'name',
    status: 'status',
    university_category_id: 'university_category_id',
    iran_province_id: 'iran_province_id',
    iran_county_id: 'iran_county_id',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.name]: yup.string().nullable().required(errorsText.blankError()),
    }),
};

export default validation;
