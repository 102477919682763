import errorsText from 'src/utils/errorsText';
import * as yup from 'yup';

const validation = {
  // defaultValue:{},
  fieldNames: {
    team: 'team_id',
    message: 'message',
    clients: 'clients',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.message]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.team]: yup.mixed(),
      [validation.fieldNames.clients]: yup.mixed(),
    }),
};
// "Passwords must match"
export default validation;
