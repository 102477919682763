import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';
import UniversitySelector from '../university/selector';
import universityCategorySelector from '../universityCategory/selector';

export const optionsLocation = [
  {
    label: 'استان',
    value: 'province',
    isProvince: true,
  },
  {
    label: 'شهر',
    value: 'city',
    isCity: true,
  },
  {
    label: 'آدرس',
    value: 'address',
    isAddress: true,
  },
];
export const optionsSelect = [
  {
    label: 'پیش فرض',
    value: false,
  },
  {
    label: 'نوع دانشگاه',
    value: 'isUniversityCategory',
    isUniversityCategory: true,
    selector: universityCategorySelector,
  },
  {
    label: 'دانشگاه',
    value: 'isUniversity',
    isUniversity: true,
    selector: UniversitySelector,
  },
];

const EForm = {
  title: '',
  routes: PATH_DASHBOARD.form,
  api: api.form,
};

export default EForm;
