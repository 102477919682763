import { Route, Routes } from 'react-router';
import Enum from '../../enum';
import List from './List';
import ById from './ById';

const TravelsComponent = () => {
  // <div>{'TravelsComponent'}</div>
  return (
    <Routes>
      <Route path={`/`} element={<List />} />
      <Route path={`/:course`} element={<ById />} />
    </Routes>
  );
};

export default TravelsComponent;
