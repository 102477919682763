import { useTranslation } from 'react-i18next';
import { ControlPoint, Edit } from '@mui/icons-material';
import { Box, Button, Grid, Modal, Typography, Alert } from '@mui/material';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalLayout from '../../../../components/ModalLayout';
import AddFormSectionInput from '../AddFormSectionInput';
import Enum from '../../../Form/enum';
import SectionInput from './SectionInput';
import QuestionComponent from '../../../../components/ui/Question/index';
import axiosInstance from '../../../../utils/axios';
import api from '../../../../services/api';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import AddSection from './Add';
import Hypothetical from './Hypothetical';

const Section = ({ data, sample }) => {
  const urlParams = useParams();
  const { t } = useTranslation();
  // ------------------------------------------------------------------------------ modal ref

  const ModalLayoutRemoveInputSectionRef = useRef();
  const ModalLayoutRemoveInputRef = useRef();
  const ModalSectionFormRef = useRef();
  const ModalAddFormRef = useRef();

  // ------------------------------------------------------------------------------ modal toggle

  const handleToggleSectionModal = () => ModalSectionFormRef.current.show((p) => !p, { data });
  const handleToggleInputModal = (params) => ModalAddFormRef.current.show((p) => !p, { data: params?.data });
  const handleRemoveInputSectionModal = (params) =>
    ModalLayoutRemoveInputSectionRef.current.show((p) => !p, { data: params?.data });
  // ------------------------------------------------------------------------------ Mutation service
  const deletingInputSection = (param) =>
    axiosInstance.delete(`${Enum.api.input}/${param?.id}`, {
      params: {
        section_id: data?.id,
      },
    });

  // ------------------------------------------------------------------------------ Mutation
  // ---------------------------------------

  const deleteInput = useMutationCustom({
    url: deletingInputSection,
    name: `${Enum?.api?.base}_update`,
    // invalidQuery: `${Enum?.api?.base}_get_${urlParams?.id}`,
    invalidQueries: [`${Enum?.api?.base}_get_${urlParams?.id}`, `${Enum?.api?.base}_gets`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
      handleRemoveInputSectionModal();
      ModalAddFormRef.current.show(false);
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  // ---------------------------------------

  // ------------------------------------------------------------------------------ handler
  const handleDelete = (data) => {
    console.log('* * * handleDelete : ', { data });
    deleteInput.mutate(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <ModalLayout ref={ModalLayoutRemoveInputSectionRef}>
        <QuestionComponent
          {...{
            loading: deleteInput.isLoading,
            title: t('question.delete.title'),
            description: t('question.delete.description'),
            button: {
              confirm: {
                label: t('question.delete.yes'),
                onClick: (_, data) => handleDelete(data),
              },
              reject: {
                label: t('question.delete.no'),
                onClick: () => handleRemoveInputSectionModal(),
              },
            },
          }}
          onClose={handleRemoveInputSectionModal}
        />
      </ModalLayout>

      {/* ----------------------------------------------------- */}
      <ModalLayout ref={ModalAddFormRef}>
        <AddFormSectionInput section={data} onRemove={(x) => handleRemoveInputSectionModal({ data: x })} />
      </ModalLayout>
      {/* ----------------------------------------------------- */}
      <ModalLayout ref={ModalSectionFormRef}>
        <AddSection section={data} />
      </ModalLayout>
      {/* ----------------------------------------------------- */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 2,
          backgroundColor: 'background.neutral',
          borderRadius: '8px',
        }}
      >
        <Typography>{data?.label}</Typography>
        {!sample ? (
          <Edit
            onClick={handleToggleSectionModal}
            sx={{
              cursor: 'pointer',
            }}
          />
        ) : (
          ''
        )}
      </Box>
      <Grid sx={{}} container spacing={3}>
        {data?.inputs?.length ? (
          data?.inputs?.map((x) => (
            <Grid key={x.id} item xs={12} md={6}>
              <SectionInput
                data={x}
                onEdit={() => handleToggleInputModal({ data: x })}
                onRemove={() => handleRemoveInputSectionModal({ data: x })}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Alert severity="info">{'موردی یافت نشد!'}</Alert>
          </Grid>
        )}
      </Grid>
      {!sample ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 2,
            borderTop: '1px solid',
            borderColor: 'grey.300',
          }}
        >
          <Typography>{'سایر امکانات'}</Typography>

          <Button onClick={handleToggleInputModal} variant="outlined" color="success">
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <Typography>{'افزودن مورد جدید'}</Typography>
              <ControlPoint />
            </Box>
          </Button>
        </Box>
      ) : (
        ''
      )}

      {!sample ? <Hypothetical section={data} /> : ''}
    </Box>
  );
};

export default Section;
