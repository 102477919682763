import { travelTypeObject } from '../../../../enumeration';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import api from '../../../../services/api';

const ECaravanSocial = {
  title: {},
  routes: PATH_DASHBOARD.caravansManagement,
  api: api.caravan,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default ECaravanSocial;
