import { Children, useEffect, useRef } from 'react';
import { uniqueId } from 'lodash';
import { Box, FormControl, FormHelperText, Grid, Input, InputAdornment, TextField } from '@mui/material';
import { Cancel, ControlPoint } from '@mui/icons-material';
import { optionsSelect } from 'src/pages/Form/enum';
import RHFRadioGroups from 'src/components/hook-form/RHFRadioGroups';
import { Controller, useFormContext } from 'react-hook-form';
import validation from '../validation';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';

const FormTypeSelect = () => {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
    trigger,
    watch,
  } = useFormContext();

  const watchType = watch(validation.fieldNames.selectType);

  const inputReference = useRef();

  const handleAdd = () => {
    const values = getValues();
    let newValue = values?.items ? [...values?.items] : [];
    newValue = [
      ...newValue,
      {
        label: inputReference.current.value,
        id: uniqueId(),
      },
    ];
    console.log({ newValue, values, inputReference: inputReference.current });
    setValue(validation.fieldNames.items, newValue);
    inputReference.current.value = '';
    trigger(validation.fieldNames.items, { shouldFocus: true });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleAdd();
    }
  };
  const values = getValues();

  console.log({ errors, values, watchType });

  useEffect(() => {
    if (!watchType) setValue(validation.fieldNames.selectType, optionsSelect[0]);
  }, [watchType]);

  return (
    <Box>
      <Grid item xs={12}>
        <RHFRadioGroups
          options={optionsSelect || []}
          name={validation.fieldNames.selectType}
          defaultValue={optionsSelect[0]}
          defaultChecked={optionsSelect[0]}
        />
      </Grid>

      {!watchType?.value ? (
        <>
          <Box
            sx={{
              // borderTop: '1px solid',
              // borderColor: 'grey.200',
              mt: 1,
              mb: 3,
            }}
          />

          <Grid spacing={3} container>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <TextField
                  fullWidth
                  inputRef={inputReference}
                  error={!!errors[validation.fieldNames.items]?.message}
                  helperText={errors[validation.fieldNames.items]?.message}
                  InputProps={{
                    onKeyDown,
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleAdd}>
                        <ControlPoint sx={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    ),
                  }}
                  label={'جواب اضافه کنید'}
                />
              </FormControl>
            </Grid>

            <Controller
              name={validation.fieldNames.items}
              control={control}
              render={({ field, fieldState: { error } }) => {
                console.log('* * * FormTypeSelect : ', { field });

                return field?.value?.length ? (
                  Children.toArray(
                    field?.value?.map((x, i) => {
                      const onRemove = () => {
                        const values = getValues();
                        if (!validation.fieldNames.items) return;
                        const newValues = values[validation.fieldNames.items].filter((_, index) => i !== index);
                        setValue(validation.fieldNames.items, newValues);
                      };
                      return (
                        // x.label ||
                        <Grid key={`item-${x.id}`} item md={6} xs={12}>
                          <FormControl fullWidth variant="standard">
                            <RHFTextField
                              name={`${validation.fieldNames.items}.${i}.label`}
                              label={'جواب'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={onRemove}>
                                    <Cancel
                                      sx={{
                                        cursor: 'pointer',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      );
                    })
                  )
                ) : (
                  <></>
                );
              }}
            />
          </Grid>
        </>
      ) : (
        ''
      )}
    </Box>
  );
};

export default FormTypeSelect;
