/* eslint-disable */
import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

// TableHeadCellCustom.propTypes = {
//   onSort: PropTypes.func,
//   orderBy: PropTypes.string,
//   headLabel: PropTypes.array,
//   rowCount: PropTypes.number,
//   numSelected: PropTypes.number,
//   onSelectAllRows: PropTypes.func,
//   order: PropTypes.oneOf(['asc', 'desc']),
//   sx: PropTypes.object,
// };

export default function TableHeadCellCustom({
  onSort,
  orderBy,
  order,
  field,
  filter,
  free,
  headProps,
  header,
  filterValue,
  setFilter,
}) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState();
  // console.log('* * * TableHeadCellCustom :', {
  //   selected,
  //   filterValue,
  //   field,
  //   filter,
  // });

  const handleAdd = (data) => {
    // console.log('* * * TableHeadCellCustom handleAdd', { filterValue, value: data?.value });
    if (filter?.multiple) {
      let newVal = filterValue || [];
      // console.log('* * * TableHeadCellCustom handleAdd', { newVal });
      let filterVal = newVal?.filter((x) => x != data?.value);
      // console.log('* * * TableHeadCellCustom handleAdd', { newVal, filterVal });

      if (filterVal?.length === newVal?.length) newVal.push(data?.value);
      else newVal = filterVal;
      // newVal = newVal?.map((x) => +x);
      // let key = `${field}[]`; //newVal?.length == 1 ? `${field}[]` : field;
      // console.log('* * * TableHeadCellCustom handleAdd', { field, newVal });
      setFilter(field, newVal?.length ? newVal : undefined);
    } else {
      setFilter(field, filterValue == data?.value ? undefined : data?.value);
    }
    // setSelected(data);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <TableCell
      sortDirection={orderBy === field ? order : false}
      sx={{
        ...(!free && { minWidth: 150 }),
        ...headProps?.sx,
      }}
      {...headProps}
    >
      {filter?.sortable ? (
        <TableSortLabel
          hideSortIcon
          active={orderBy === field}
          direction={orderBy === field ? order : 'asc'}
          onClick={() => onSort(field)}
          sx={{ textTransform: 'capitalize' }}
        >
          {header}

          {orderBy === field ? (
            <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
          ) : null}
        </TableSortLabel>
      ) : filter?.lookup ? (
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
            '.rtl-9q3kl4-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
              py: '0 !important',
              border: '0 !important',
            },
            '.MuiSelect-nativeInput': {
              border: '0 !important',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: '0 !important',
            },
            '.rtl-1uvz7wx-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
              fontSize: 'inherit',
              fontFamily: 'inherit',
              color: 'inherit',
              fontWeight: 'inherit',
            },
            // input: {
            //   fontSize: 'inherit',
            //   fontFamily: 'inherit',
            //   color: 'inherit',
            // },
          }}
        >
          {/* <Box onClick={handleOpen}>{header}</Box> */}
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            // multiple={filter?.multiple}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={header}
            // onChange={(e, data) => {
            //   console.log('* * * TableHeadCellCustom :', { e, data });
            // }}
            // input={<input label="Tag" />}
            renderValue={() => header}

            // MenuProps={MenuProps}
          >
            {filter?.lookup?.map((x) => (
              <MenuItem
                key={x?.label}
                // value={x?.value}
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
                onClick={() => handleAdd(x)}
              >
                <Checkbox
                  color="success"
                  checked={
                    // false
                    filter?.multiple ? (filterValue || [])?.includes(String(x.value)) : filterValue == x.value
                    // personName.indexOf(name) > -1
                  }
                />
                <ListItemText primary={x?.label} />
              </MenuItem>
            ))}
          </Select>
          {/* {filter?.lookup ? (
            <Box display={'flex'} alignItems={'center'}>
              <ArrowDropDownIcon />
            </Box>
          ) : (
            ''
          )} */}
        </Box>
      ) : (
        header
      )}
      {/* {header} */}
    </TableCell>
  );
}
