import { Children, useRef } from 'react';
import { Box, FormControl, FormHelperText, Grid, Input, InputAdornment, TextField } from '@mui/material';
import { Cancel, ControlPoint } from '@mui/icons-material';
import { Controller, useFormContext } from 'react-hook-form';
import validation from '../validation';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import { RHFUploadSingleFile } from '../../../../../components/hook-form/RHFUpload';

const FormTypeSocial = () => {
  const { control, setValue, getValues } = useFormContext();

  const inputReference = useRef();

  const handleAdd = () => {
    const values = getValues();
    let newValue = values?.items ? [...values?.items] : [];
    newValue = [
      ...newValue,
      {
        label: inputReference.current.value,
      },
    ];
    console.log({ newValue, values, inputReference: inputReference.current });
    setValue(validation.fieldNames.items, newValue);
    inputReference.current.value = '';
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleAdd();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          borderTop: '1px solid',
          borderColor: 'grey.200',
          mt: 1,
          mb: 3,
        }}
      />
      <Grid spacing={3} container>
        {/* <Grid item xs={12}>
        <Box
          sx={{
            borderTop: '1px solid',
            borderColor: 'grey.200',
            mb: 2,
          }}
        />
      </Grid> */}

        <Grid item xs={12}>
          <RHFTextField
            name={validation.fieldNames.link}
            label={'لینک شبکه'}
            InputProps={{
              endAdornment: <InputAdornment position="end">{'.....www'}</InputAdornment>,
              sx: {
                textAlign: 'end',
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <RHFUploadSingleFile
            name={validation.fieldNames.image}
            title={'تصویر ایکون مورد نظرتان را آپلود کنید.'}
            description={`فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
فرمت های قابل قبول: gif، JPG، PNG،`}
          />
        </Grid>

        {/* <Controller
        name={validation.fieldNames.items}
        control={control}
        render={({ field, fieldState: { error } }) => {
          console.log({ field });

          return field?.value?.length ? (
            Children.toArray(
              field?.value?.map((x, i) => {
                const onRemove = () => {
                  const values = getValues();
                  if (!validation.fieldNames.items) return;
                  const newValues = values[validation.fieldNames.items].filter((_, index) => i !== index);
                  setValue(validation.fieldNames.items, newValues);
                };
                return (
                  <Grid key={`item-${x.label}`} item md={6} xs={12}>
                    <FormControl fullWidth variant="standard">
                      <RHFTextField
                        name={`${validation.fieldNames.items}.${i}.label`}
                        label={'جواب'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" onClick={onRemove}>
                              <Cancel
                                sx={{
                                  cursor: 'pointer',
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                );
              })
            )
          ) : (
            <></>
          );
        }}
      /> */}
      </Grid>
    </Box>
  );
};

export default FormTypeSocial;
