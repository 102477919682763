import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import createQueryString from 'src/utils/createQueryString';
import RemoveNullObjectValue from '../utils/RemoveNullObjectValue';
import useDebounce from './useDebounce';

// ----------------------------------------------------------------------

export default function useTable({ queryString, maxRowSelect, ...props } = {}) {
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params, reset) => ({
    pathname: location.pathname,
    search: createQueryString(RemoveNullObjectValue({ ...(!reset && { ...queryString }), ...params })).toString(),
  });

  const navigateUrl = (obj, reset) => navigate(changeUrl(obj, reset));

  const [dense, setDense] = useState(props?.defaultDense || false);

  // const [orderBy, setOrderBy] = useState(props?.defaultOrderBy || 'name');

  // const [order, setOrder] = useState(props?.defaultOrder || 'asc');

  const [page, setPage] = useState(props?.defaultCurrentPage || 0);

  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || 5);

  const [selected, setSelected] = useState();
  const [selectedObject, setSelectedObject] = useState();
  const [orderBy, order] = queryString?.sort?.split('@') || [];
  // const onSort = (id) => {
  //   const isAsc = orderBy === id && order === 'asc';
  //   if (id !== '') {
  //     setOrder(isAsc ? 'desc' : 'asc');
  //     setOrderBy(id);
  //   }
  // };
  const onSort = (id) => {
    const [orderBy, order] = queryString?.sort?.split('@');
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      navigateUrl({ sort: `${id}@${isAsc ? 'desc' : 'asc'}` });
      // setOrder(isAsc ? 'desc' : 'asc');
      // setOrderBy(id);
    }
  };

  const onSelectRow = (data) => {
    // console.log('* * * onSelectRow : ', { id, selectedIndex });
    const newSelectedObject = { ...(selectedObject && selectedObject) };
    if (newSelectedObject[data?.id]) {
      delete newSelectedObject[data?.id];
    } else {
      if (maxRowSelect && +maxRowSelect?.count === +selected?.length) {
        toast.info(maxRowSelect?.error);
        return;
      }
      newSelectedObject[data?.id] = data;
    }
    setSelectedObject(newSelectedObject);
    setSelected(Object.values(newSelectedObject));
  };

  const onResetSelectedRow = () => {
    setSelected();
    setSelectedObject();
  };

  // const onSelectRow = (id) => {
  //   const selectedIndex = selected.indexOf(id);

  //   // console.log('* * * onSelectRow : ', { id, selectedIndex });
  //   let newSelected = [];
  //   let newSelectedObject = {};

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }
  //   // setSelectedObject(p=>{...p,})
  //   setSelected(newSelected);
  // };

  const onSelectAllRows = (checked, newSelecteds) => {
    if (checked) {
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onChangePage = (event, newPage) => {
    console.log({ newPage, event });
    // setPage(newPage);
    navigateUrl({ page: newPage });
  };

  const onChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    navigateUrl({ page: 1, perPage: parseInt(event.target.value, 10) });
  };

  const onChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const onSearch = (text) => {
    navigateUrl({ search: text, page: 1 });
  };

  const setFilter = (key, value) => {
    console.log('* * * useTable setFilter', { key, value });
    navigateUrl({ [key]: value, page: 1 });
  };

  const { debounce } = useDebounce({ setDebounce: (x) => onSearch(x) });
  // filter

  return {
    dense,
    order,
    page: page || queryString?.page,
    setPage,
    orderBy,
    rowsPerPage: rowsPerPage || queryString?.perPage,
    limit: queryString?.perPage,
    //
    selected,
    selectedObject,
    setSelected,
    onResetSelectedRow,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangePage,
    onChangeDense,
    onChangeRowsPerPage,

    onSearch: debounce,
    search: queryString?.search,
    queryString,
    setFilter,
  };
}

// ----------------------------------------------------------------------

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function emptyRows(page, rowsPerPage, arrayLength) {
  return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}
