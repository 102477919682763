import { Badge, Box, Button, Container, Modal, Typography } from '@mui/material';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useRef, useState } from 'react';
import ModalLayout from 'src/components/ModalLayout';
import QuestionComponent from 'src/components/ui/Question';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import api from 'src/services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import useQueryString from '../../../utils/useQueryString';
import Enum from '../enum';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../../components/Iconify';
import AddComponent from '../../Course/Add';
import TableComponent from '../../../components/table/TableComponent';
import { tableData } from './tableInfo';
import useTable from '../../../hooks/useTable';

const List = () => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const location = useLocation();
  const ModalLayoutQuestionInputRef = useRef();
  const [toggleModal, setToggleModal] = useState();
  const queryString = useQueryString({ sort: 'createdAt@desc' });
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  const isRecord = queryParams.isRecord;
  const previous = location?.state;
  const { themeStretch } = useSettings();

  const tableSetting = useTable({ queryString });
  console.log({ tableSetting, queryParams });

  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${queryParams.travel}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${queryParams.travel}`,
    url: getTravel,
    params: { id: queryParams.travel },
  });

  // ------------------------------------------------------- query Get
  const getting = async () =>
    axiosInstance.get(`${Enum?.api?.base}/${queryParams.travel}`, { params: { ...queryString } });

  const {
    data: { data = {} } = {},
    error,
    isError,
    isLoading,
    refetch,
    isFetching,
  } = useQueryCustom({
    name: `${Enum?.api?.base}_get_${queryParams.travel}_bank`,
    url: getting,
    params: { ...queryString },
  });
  // ------------------------------------------------------- query bank
  const onSuccessMutating = () => {
    ModalLayoutQuestionInputRef.current.show((p) => false);
    tableSetting.onSelectAllRows(false);
    toast.success(t('successfully'));
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  const postSendToBank = async (params) => axiosInstance.post(`${api?.bank?.base}`, params);
  const SendToBankMutation = useMutationCustom({
    url: postSendToBank,
    name: `${api?.SendToBank?.base}_post`,
    invalidQuery: `${Enum?.api?.base}_get_${queryParams.travel}_bank`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  const handleSendToBank = () => {
    SendToBankMutation.mutate({
      travel_id: queryParams.travel,
      client_id: tableSetting?.selected,
    });
  };

  const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params);

  const questionsText = {
    sendToBank: {
      title: 'آیا از ارسال به بانک مطمئن هستید؟',
      description: 'پس از تایید ارسال به بانک انجام خواهد شد . آیا ادامه میدهید؟',
      button: {
        confirm: {
          label: 'بله ، تایید میکنم',
          onClick: handleSendToBank,
          loading: SendToBankMutation?.isLoading,
        },
        reject: {
          label: 'خیر',
          onClick: () => handleQuestionModal(),
          disabled: SendToBankMutation?.isLoading,
        },
      },
    },
  };

  const handleModalMiddlewareClick = (key) => {
    handleQuestionModal(questionsText[key]);
  };
  // const data = [
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  //   {
  //     name: 'hasan',
  //     lastName: 'jafari',
  //     nationalCode: '23561484155',
  //     phoneNumber: '09111111111',
  //     reason: 'jafari',
  //     createdAt: '1403/04/05',
  //   },
  // ];

  const tableOptions = tableData();
  const title = `مدیریت ${Enum.title.name[0]}`;
  console.log({ tableOptions, data, resTravel });
  return (
    <Page title={title}>
      <ModalLayout ref={ModalLayoutQuestionInputRef}>
        {
          <QuestionComponent
            {...{
              loading: SendToBankMutation?.isLoading,
              // title: 'آیا از ارسال به بانک مطمئن هستید؟',
              // description: 'پس از تایید ارسال به بانک انجام خواهد شد . آیا ادامه میدهید؟',
              // button: {
              //   confirm: {
              //     label: 'question.yesProcess',
              //     onClick: () => handleModalMiddlewareClick('sendToBank'),
              //   },
              //   reject: {
              //     label: 'question.no',
              //     onClick: handleQuestionModal,
              //   },
              // },
            }}
            // onClose={handleQuestionModal}
          />
        }
      </ModalLayout>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        {/* <HeaderBreadcrumbs
          back={`${PATH_DASHBOARD.form.root(urlParams.type, urlParams.travel)}`}
          heading={sample ? resQuery?.data?.data?.name : resQuery?.data?.data?.travel?.name}
        >
          {!sample ? <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> : ''}
        </HeaderBreadcrumbs> */}
        <HeaderBreadcrumbs
          heading={isRecord ? resTravel?.data?.data?.name : title}
          back={
            previous?.pathname
              ? { pathname: previous?.pathname, search: new URLSearchParams(previous?.query || {}).toString() }
              : false
          }
          links={[
            { name: 'داشبورد', href: PATH_DASHBOARD.root },
            { name: title, href: Enum.routes.list },
            { name: 'لیست' },
          ]}
          action={
            !isRecord && tableSetting?.selected?.length ? (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* <Badge sx={{ border: '1px' }} badgeContent={tableSetting?.selected?.length} color="success"> */}
                <Button
                  variant="contained"
                  color="success"
                  // component={Link}
                  // to={baseUrl + Enum.routes.SendToBank}
                  endIcon={<ControlPointOutlinedIcon />}
                  onClick={() => handleModalMiddlewareClick('sendToBank')}
                >
                  {'ارسال به بانک'}
                </Button>
                {/* </Badge> */}

                {/* <Button
                  variant="contained"
                  color="success"
                  // component={Link}
                  // to={baseUrl + Enum.routes.SendToBank}
                  endIcon={<ControlPointOutlinedIcon />}
                >
                  {'استعلام'}
                </Button> */}
              </Box>
            ) : (
              ''
            )
          }
        >
          {isRecord ? <Typography sx={{ mt: 1 }}>{title}</Typography> : ''}
        </HeaderBreadcrumbs>
        <TableComponent
          active={{
            selectable: !isRecord && { find: (data) => data?.client?.id },
            // rowNumber: true,
          }}
          options={tableOptions}
          data={data?.data}
          tableSetting={tableSetting}
          pagination={{
            totalPage: data?.meta?.last_page,
            total: data?.meta?.total,
          }}
          loading={isLoading}
        />{' '}
      </Container>
    </Page>
  );
};

export default List;
