import { Route, Routes } from 'react-router';
import Enum from './enum';
import List from './List';

const Records = () => {
  return (
    // <div>{'hiiii'}</div>
    <Routes>
      <Route path={`/`} element={<List />} />
    </Routes>
  );
};

export default Records;
