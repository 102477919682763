import { uniqueId } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import validation from '../validation';
import Row from './Row';

const initialValue = { id: uniqueId() };

const AddableFormLocation = () => {
  const { control } = useFormContext();

  return (
    <Box>
      <Typography sx={{ my: 3 }}>{'انتخاب بر اساس موقعیت مکانی'}</Typography>
      <Typography sx={{ my: 3, fontWeight: '300', fontSize: '14px', color: 'grey.600' }}>
        {'استان یا شهر مورد نظر را انتخاب کنید سپس مشخص کنید چند درصد از زائران از این موقعیت مکانی تشکیل شوند.'}
      </Typography>

      <Controller
        name={validation.fieldNames.ddd}
        control={control}
        render={({ field: { onChange, value } }) => {
          const baseValue = [...(value || [initialValue])];
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 5, md: 3 },
              }}
            >
              {baseValue?.map?.((val, index) => {
                return (
                  <Row
                    key={val.id}
                    {...{
                      index,
                      length: baseValue?.length,
                      last: index > 0 && baseValue?.length === index + 1,
                      handleAdd: () => {
                        onChange([...baseValue, initialValue]);
                      },
                      handleRemove: () => {
                        onChange(baseValue?.filter((_, i) => i !== index));
                      },
                    }}
                    basename={`${validation.fieldNames.ddd}.[${index}]`}
                  />
                );
              })}
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default AddableFormLocation;
