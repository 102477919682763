import { Box, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormContext } from 'react-hook-form';
import validation from '../validation';
import RHFSelector from '../../../../../components/hook-form/RHFSelector';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import ProvinceSelector from '../../../../../components/selector/Province';
import CitySelector from '../../../../../components/selector/City';

const AddableFormLocation = ({ handleAdd, last, length, handleRemove, basename }) => {
  const { setValue, watch } = useFormContext();

  const handleChange = (a, v) => {
    console.log('* * * FormInputLocationProvince handleChange : ', { a, v });
    setValue(`${basename}.${validation.fieldNames.fff}`, undefined);
  };

  const watchProvince = watch(`${basename}.${validation.fieldNames.eee}`);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <ProvinceSelector.Element
            name={`${basename}.${validation.fieldNames.eee}`}
            label={'استان'}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CitySelector.Element
            name={`${basename}.${validation.fieldNames.fff}`}
            label={'شهر'}
            provinceId={watchProvince?.value}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RHFTextField
            options={[
              {
                label: 'همه',
                value: 1,
              },
            ]}
            name={`${basename}.${validation.fieldNames.ggg}`}
            label={'درصد'}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {length > 1 ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </Box>
        ) : (
          ''
        )}

        {length === 1 || last ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleAdd}
          >
            <AddIcon />
          </Box>
        ) : length > 1 ? (
          <Box
            sx={{
              width: '56px',
              height: '56px',
            }}
          />
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default AddableFormLocation;
