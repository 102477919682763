import * as yup from 'yup';
import errorsText from '../../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    aaa: 'aaa',
    bbb: 'bbb',
    ccc: 'ccc',
    ddd: 'ddd',
    eee: 'eee',
    fff: 'fff',
    ggg: 'ggg',
    hhh: 'hhh',
    iii: 'iii',
    jjj: 'jjj',
    edu: 'edu',
    status: 'status',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.aaa]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.bbb]: yup.string().required(errorsText.blankError()),
    }),
};

export default validation;
