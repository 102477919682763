import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

// 0 => 'ثبت نام اولیه',
// 1 => 'اطلاعات تکمیلی',
// 2 => 'اطلاعات هویتی',
// 3 => 'اطلاعات بانکی',
// 4 => 'اطلاعات نظام وظیفه',
// 5 => 'دیگر فرم ها',

const objectFormType = {
  0: {
    label: 'ثبت نام اولیه',
    value: 0,
  },
  1: {
    label: 'اطلاعات تکمیلی',
    value: 1,
  },
  2: {
    label: 'اطلاعات هویتی',
    value: 2,
  },
  3: {
    label: 'اطلاعات بانکی',
    value: 3,
  },
  4: {
    label: 'اطلاعات نظام وظیفه',
    value: 4,
  },
  5: {
    label: 'دیگر فرم ها',
    value: 5,
  },
};

const EForms = {
  title: '',
  routes: PATH_DASHBOARD.forms,
  api: api.form,
  enum: {
    array: Object.values(objectFormType),
    object: objectFormType,
  },
};

export default EForms;
