import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useMemo, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFTextField from '../../../../../../../../components/hook-form/RHFTextField';
import ProvinceSelector from '../../../../../../../../components/selector/Province';
import AcceptOrRejectWithQuestionsWithReason from '../../AcceptOrRejectWithQuestionsWithReason/index';

const FormInputLocationProvince = ({ name, data, ...props }) => {
  const { setValue } = useFormContext();

  const handleChange = (a, v) => {
    console.log('* * * FormInputLocationProvince handleChange : ', { a, v });
    if (data?.childCityName) setValue(data?.childCityName, undefined);
  };
  console.log('* * * FormInputLocationProvince : ', { data });

  return (
    <Box>
      <ProvinceSelector.Element
        geById={data?.client_inputs?.[0]?.value}
        name={name}
        label={data?.label}
        onChange={handleChange}
        InputProps={
          data?.need_confirm
            ? // && isHistory
              {
                endAdornment: (
                  <InputAdornment position="start">
                    <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                  </InputAdornment>
                ),
              }
            : ''
        }
        {...props}
      />
    </Box>
  );
};

export default memo(FormInputLocationProvince);
