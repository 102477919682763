import { store } from '../redux/store';

export const hasAccess = (accessPermission, type) => {
  const { admin } = store.getState();
  const userPermissions = admin?.data?.role?.permission || [];
  // console.log('* * * hasAccess : ', { accessPermission, type, userPermissions });

  // return true;
  if (admin?.data?.isSuperadmin) return true;
  if (accessPermission) {
    if (typeof accessPermission === 'string') {
      return (
        userPermissions?.findIndex?.((permission) => {
          // console.log({
          //   aaaaa: `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}`,
          // });
          return (
            `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}` ===
            accessPermission
          );
        }) > -1
      );
    }
    if (accessPermission?.length) {
      const valid = userPermissions?.some?.((permission) =>
        accessPermission?.includes?.(
          `${permission?.model} ${permission?.action}${permission?.type ? ` ${permission?.type}` : ''}`
        )
      );
      // console.log({ accessPermission, valid, userPermissions });
      return valid;
    }
  }

  if (type) return userPermissions?.some?.((permission) => permission?.type === type);
  return true;
};
