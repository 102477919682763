import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EVisitorManagement = {
  title: 'مدیریت زائرین',
  routes: PATH_DASHBOARD.visitorManagement,
  api: api.travel,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default EVisitorManagement;
