import errorsText from 'src/utils/errorsText';
import * as yup from 'yup';

const validation = {
  // defaultValue:{},
  fieldNames: {
    send_to: 'send_to',
    message: 'message',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.message]: yup.string().required(errorsText.blankError()),
      [validation.fieldNames.send_to]: yup.string().required(errorsText.blankError()),
    }),
};
// "Passwords must match"
export default validation;
