import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali, fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: '',
    header: 'متن پیام',
    Component: ({ param }) => param?.message || '-',
  },
  {
    key: '',
    header: 'نتیجه ارسال',
    Component: ({ param }) => param?.status || '-',
  },
  {
    key: '',
    header: 'تاریخ ارسال',
    Component: ({ param }) => fDateTime(param?.created_at) || '-',
  },
  {
    key: '',
    header: 'ارسال کننده',
    Component: ({ param }) => param?.admin_id?.name || '-',
  },
  {
    key: '',
    header: 'ارسال شده به',
    Component: ({ param }) => <Box style={{ direction: 'ltr' }}>{param?.send_to?.replace(/\n/g, ` , `) || '-'}</Box>,
  },
];
