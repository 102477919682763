import { useMemo, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import axiosInstance from '../../../utils/axios';
import api from '../../../services/api';
import Enum from '../enum';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import Section from './components/Section';
import AddForm from '../Add';
import ModalLayout from '../../../components/ModalLayout';
import WaitingBox from '../../../components/WaitingBox/index';

const ById = () => {
  const { travel: id, type, ...queryParams } = useParams();
  const { themeStretch } = useSettings();
  const location = useLocation();
  const isRecord = queryParams.isRecord;
  const previous = location?.state;

  const ModalFormRefAdd = useRef();
  const handleToggleNewFormModal = ({ data }) => ModalFormRefAdd.current.show((p) => !p, { data });

  // ------------------------------------------------------- query Get
  const getTravel = async () => axiosInstance.get(`${api.travel.base}/${id}`);

  const resTravel = useQueryCustom({
    name: `${api.travel.base}_get_${id}`,
    url: getTravel,
    params: { id: id },
  });
  // ------------------------------------------------------- query Get

  const getById = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${api.travel.base}/${params?.id}`);
  };

  const getSample = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${Enum.api.sampleForm}`, {
      params: {
        type,
      },
    });
  };
  const resQuery = useQueryCustom({
    name: `${api.travel.base}_get_${id}`,
    url: getById,
    params: { id },
  });

  const resSampleQuery = useQueryCustom({
    name: `${Enum.api.sampleForm}_get`,
    url: getSample,
  });

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  console.log({ resQuery, resSampleQuery });
  const travel = resQuery?.data?.data?.[0]?.travel;
  // resQuery?.data?.data?.sections
  // resQuery?.data?.data?.travel

  const validSample = resSampleQuery?.data?.data;

  //  useMemo(() => {
  //   return resSampleQuery?.data?.data?.filter(
  //     (x) => !resQuery?.data?.data?.forms?.some((y) => x.id === y.id || x.name === y.name)
  //   );
  // }, [resSampleQuery]);

  return resQuery.isLoading ? (
    <WaitingBox />
  ) : (
    <Page title={travel?.name}>
      <ModalLayout ref={ModalFormRefAdd}>
        <AddForm />
      </ModalLayout>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={isRecord ? resTravel?.data?.data?.name : resQuery?.data?.data?.name}
          back={
            previous?.pathname
              ? { pathname: previous?.pathname, search: new URLSearchParams(previous?.query || {}).toString() }
              : false
          }
        />
        {/* {isRecord ? <Typography sx={{ mt: 1 }}>{resQuery?.data?.data?.name}</Typography> : ''}
        </HeaderBreadcrumbs> */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {!isRecord ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button variant="contained" color="success" onClick={handleToggleNewFormModal}>
                  {'افزودن فرم'}
                </Button>
              </Box>
            ) : (
              ''
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                backgroundColor: 'background.neutral',
                borderRadius: '8px',
              }}
            >
              <Typography>{'فرم های انتشار یافته'}</Typography>
            </Box>
            <Grid sx={{}} container spacing={3}>
              {resQuery?.data?.data?.forms?.length ? (
                resQuery?.data?.data?.forms?.map((x) => (
                  <Grid key={x.id} item xs={12}>
                    <Section
                      data={x}
                      handleEdit={() => handleToggleNewFormModal({ data: x })}
                      refetch={() => resQuery.refetch()}
                      disabled={{
                        show: isRecord,
                        edit: isRecord,
                        delete: isRecord,
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: 2,
                backgroundColor: 'background.neutral',
                borderRadius: '8px',
              }}
            >
              <Typography>{'استفاده از فرم های آماده'}</Typography>
            </Box>
            <Grid sx={{}} container spacing={3}>
              {validSample?.length ? (
                validSample?.map((x) => (
                  <Grid key={x.id} item xs={12}>
                    <Section
                      sample
                      data={x}
                      handleEdit={() => handleToggleNewFormModal({ data: x })}
                      refetch={() => resSampleQuery.refetch()}
                      disabled={{
                        show: isRecord,
                        edit: isRecord,
                        delete: isRecord,
                      }}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Alert severity="info">{'موردی یافت نشد!'}</Alert>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>

        {/* {resQuery?.data?.data?.sections?.map((x) => (
          <Section data={x} />
        ))} */}
      </Container>
    </Page>
  );
};

export default ById;
