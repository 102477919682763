import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../../enum';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../../components/WaitingBox/index';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import AddableFormLocation from './AddableFormLocation/index';
import AddableFormEducation from './AddableFormEducation/index';
import AddableFormMarried from './AddableFormMarried';

const Add = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;
  const baseUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}${Enum.routes.lottery}`;

  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root}`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  const onErrorMutating = (error) => {
    console.log('* * * onErrorMutating :', { error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  const onSuccess = (resData) => {
    console.log('* * * onSuccess', { resData });
    const resetData = {
      [validation.fieldNames.name]: resData?.data?.data?.name,
    };
    console.log('* * * onSuccess', { resetData });
    reset(resetData);
  };
  const dataById = useQueryCustom({
    name: `getById_${Enum?.api?.base}_${paramId}`,
    url: getById,
    onSuccess: onSuccess,
    enabled: !!paramId,
  });

  const handleLottery = () => {
    navigate(baseUrl + '/list');
  };

  const watchStartDate = watch(validation.fieldNames.startDate);
  console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const reqData = {
      [validation.fieldNames.name]: values[validation.fieldNames.name],
    };

    console.log('* * * onSubmit : ', { reqData, values });
    mutate(reqData);
  };
  console.log({ queryParams });
  return dataById.isLoading ? (
    <WaitingBox />
  ) : (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
      }}
    >
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ my: 3 }}>{'تعداد افراد شرکت کننده در قرعه کشی'}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.aaa} label={'تعداد کل افراد'} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RHFTextField name={validation.fieldNames.bbb} label={'تعداد افراد مورد نیاز'} />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        <Typography sx={{ my: 3 }}>{'نوع قرعه کشی'}</Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RHFSelector
                options={[
                  {
                    label: 'همه',
                    value: 1,
                  },
                ]}
                name={validation.fieldNames.ccc}
                label={'دسته قرعه کشی'}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        {/* <RHFTextField name={validation.fieldNames.name} label={'نام'} /> */}
        <AddableFormLocation />

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />
        <AddableFormMarried />

        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'grey.300',
            my: 3,
          }}
        />

        <AddableFormEducation />

        <Box
          sx={{
            display: 'flex',
            mt: 6,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton
            sx={{
              minWidth: '220px',
            }}
            type="submit"
            variant="contained"
            color={'success'}
            loading={isLoading}
            onClick={handleLottery}
          >
            {'قرعه کشی'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default Add;
