import { InputAdornment, Box } from '@mui/material';
import { useMemo } from 'react';
import RHFSelector from '../../../../../../../components/hook-form/RHFSelector';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputSelect = ({ name, data, disabled, ...props }) => {
  const options = useMemo(() => {
    // const param= JSON.parse(data?.options||'{}');
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
      selector: data?.options?.selectType?.selector,
    };
  }, [data]);

  if (options.selector) {
    console.log('* * * FormInputSelect : ', { data, props });

    return (
      <options.selector.Element
        geById={data?.client_inputs?.[0]?.value}
        name={name}
        label={data?.label}
        disabled={disabled}
        {...props}
        log={options.selector}
      />
    );
  }

  return (
    <RHFSelector
      options={options?.array || []}
      name={name}
      label={data?.label}
      disabled={disabled}
      // onChange={onChange}
      {...props}
      InputProps={
        data?.need_confirm
          ? {
              endAdornment: (
                <InputAdornment
                  // onMouseDown={(event) => {
                  //   // event.preventDefault();
                  //   event.stopPropagation();
                  // }}
                  // onClick={(event) => {
                  //   event.preventDefault();
                  //   event.stopPropagation();
                  // }}
                  position="start"
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                </InputAdornment>
              ),
            }
          : ''
      }
      // inputProps={{
      //   startAdornment: <InputAdornment position="start">asdasd</InputAdornment>,
      // }}
    />
  );
  // return <RHFSelector options={options?.array || []} name={name} label={data?.label} disabled={disabled} {...props} />;
};

export default FormInputSelect;
