import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete }) => [
  {
    key: 'name',
    header: 'نام',
    Component: ({ param }) => param?.name || '-',
  },

  {
    key: 'created_at',
    header: 'تاریخ ایجاد',
    Component: ({ param }) => fDate(param?.created_at) || '-',
  },

  {
    key: 'actions',
    header: '',
    Component: ({ param }) => (
      <MoreTable>
        <Link to={`${Enum.routes.root + Enum.routes.edit}/${param?.id}`}>
          <MoreTableItem isEdit />
        </Link>
        <MoreTableItem isDelete onClick={() => onDelete(param)} />
      </MoreTable>
    ),
    free: true,
  },
];
