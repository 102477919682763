import { useParams } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import buildAccess from 'src/permission/buildAccess';
import PermissionRoute from 'src/permission/permissionRoute';
import { travelTypeObject } from 'src/enumeration';
import Enum from './enum';
import List from './List';
import Add from './Add';
import Show from './Show';

const CaravansManagement = () => {
  const urlParams = useParams();
  const access = buildAccess(Enum.routes.name, travelTypeObject[urlParams.type]?.type);

  return (
    <Routes>
      <Route
        path={`${Enum.routes.add}`}
        element={<PermissionRoute element={<Add access={access} />} permissions={access?.create} />}
      />
      <Route
        path={`${Enum.routes.edit}/:id`}
        element={<PermissionRoute element={<Add access={access} />} permissions={access?.update} />}
      />
      <Route
        path={`${Enum.routes.show}/:id/:tab/*`}
        element={<PermissionRoute element={<Show access={access} />} permissions={access?.read} />}
      />
      <Route
        path={`${Enum.routes.list}`}
        element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />}
      />
    </Routes>
    // <Routes>
    //   <Route path={`${Enum.routes.add}`} element={<Add />} />
    //   <Route path={`${Enum.routes.edit}/:id`} element={<Add />} />
    //   <Route path={`${Enum.routes.show}/:id/:tab/*`} element={<Show />} />
    //   <Route path={`${Enum.routes.list}`} element={<List />} />
    // </Routes>
  );
};

export default CaravansManagement;
