import buildSideSubitems from 'src/utils/buildSideSubitems';
import MoreTable from 'src/components/table/MoreTable';
import MoreTableItem from 'src/components/table/MoreTable/MoreTableItem';
import { Link } from 'react-router-dom';
import { fDateTime } from '../../../utils/formatTime';
import { travelTypeObject, statusType } from '../../../enumeration/index';

export const tableData = ({ queryString }) => [
  {
    key: 'name',
    header: 'نام',
    Component: ({ param }) => param?.name || '-',
  },
  {
    key: 'type',
    header: 'حج',
    Component: ({ param }) => travelTypeObject[param?.type]?.label || '-',
  },

  {
    key: 'registration_start_at',
    header: 'تاریخ شروع',
    Component: ({ param }) => (param?.registration_start_at && fDateTime(param?.registration_start_at)) || '-',
  },
  {
    key: 'registration_finish_at',
    header: 'تاریخ پایان',
    Component: ({ param }) => (param?.registration_finish_at && fDateTime(param?.registration_finish_at)) || '-',
  },
  {
    key: 'is_active',
    header: 'وضعیت',
    Component: ({ param }) => statusType[param?.is_active]?.label || '-',
  },
  {
    key: 'actions',
    header: '',
    Component: ({ param }) => {
      const items = buildSideSubitems(travelTypeObject[param?.type], param?.id, true);
      return (
        <MoreTable>
          {items?.map((x) => (
            <Link to={x.path} state={{ query: queryString, pathname: window.location.pathname }}>
              <MoreTableItem label={x.title} />
            </Link>
          ))}
          {/* {!disabled?.update ? (
          <Link to={`${Enum.routes.root + Enum.routes.edit}/${param?.id}`}>
            <MoreTableItem isEdit />
          </Link>
        ) : (
          ''
        )}
        {!disabled?.delete ? <MoreTableItem isDelete onClick={() => onDelete(param)} /> : ''} */}
        </MoreTable>
      );
    },
    free: true,
  },
];
