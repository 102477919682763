import { useParams, Route, Routes } from 'react-router-dom';
import { travelTypeObject } from 'src/enumeration';
import buildAccess from 'src/permission/buildAccess';
import PermissionRoute from 'src/permission/permissionRoute';
import Enum from './enum';
import List from './List';

const DutySystemManagement = () => {
  const urlParams = useParams();
  const access = buildAccess(Enum.routes.name, travelTypeObject[urlParams.type]?.type);

  return (
    <Routes>
      <Route
        path={`${Enum.routes.list}`}
        element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />}
      />
    </Routes>
  );
};

export default DutySystemManagement;
