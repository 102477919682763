import * as yup from 'yup';
import errorsText from '../../../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    social: 'social',
    link: 'link',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.social]: yup.mixed().required(errorsText.blankError()),
      [validation.fieldNames.link]: yup.string().required(errorsText.blankError()),
    }),
};
// "Passwords must match"
export default validation;
