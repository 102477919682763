/* eslint-disable */
import { Box, Chip } from '@mui/material';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { formTypesObject } from 'src/enumeration';
import createQueryString from 'src/utils/createQueryString';
import { fDate } from 'src/utils/formatTime';
import RemoveNullObjectValue from 'src/utils/RemoveNullObjectValue';

const ShowFilters = ({ queryString }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const changeUrl = (params) => ({
    pathname: location.pathname,
    search: createQueryString(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
  });

  const navigateUrl = (obj) => navigate(changeUrl(obj));

  const data = useMemo(() => {
    let newFilters;
    try {
      newFilters = JSON.parse(queryString?.filters || '');
      return newFilters;
    } catch (error) {}
    console.log({ newFilters, queryString });
    if (!newFilters) return;
  }, [queryString]);
  // console.log('* * * ShowFilters : ', { data });
  const onDelete = (data, index) => {
    // console.log('* * * onDelete : ', { data });

    let newFilters;
    try {
      newFilters = JSON.parse(queryString?.filters || '');
      newFilters = newFilters?.filter((_, i) => index != i);
      console.log('* * * onDelete : ', { newFilters });
      navigateUrl({ filters: newFilters?.length ? JSON.stringify(newFilters) : '' });
      return newFilters;
    } catch (error) {}
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
      }}
    >
      {data?.map((x, i) => {
        const isDate = x?.type === formTypesObject.DATE.value;
        const isLocation = x?.type === formTypesObject.LOCATION.value;
        return (
          <Chip
            label={
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Box> {`${x?.label}`}</Box>:<Box>{`${isDate ? fDate(x.value) : x?.title || x?.value}`}</Box>
              </Box>
            }
            onDelete={() => onDelete(x, i)}
          />
        );
      })}
    </Box>
  );
};

export default ShowFilters;
