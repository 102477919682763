import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { haveHasType, caravanType } from '../../../enumeration';
import { fDate, fDateJalali } from '../../../utils/formatTime';
import Enum from '../enum';
import TableMoreMenu from '../../../components/table/TableMoreMenu';
import Iconify from '../../../components/Iconify';
import MoreTable from '../../../components/table/MoreTable';
import MoreTableItem from '../../../components/table/MoreTable/MoreTableItem';

export const tableData = ({ baseRoute, onDelete, disabled }) => [
  {
    key: 'name',
    header: 'نام و نام خانوادگی',
    Component: ({ param }) =>
      param?.name || param?.last_name
        ? // <Link to={PATH_DASHBOARD.}>
          //  {
          `${param?.name || '-'} ${param?.last_name || '-'}`
        : // }
          // </Link>
          '-',
  },

  {
    key: 'agent',
    header: 'دسته عامل',
    Component: ({ param }) => param?.agent?.name || '-',
  },
  {
    key: 'nationalCode',
    header: 'کدملی',
    Component: ({ param }) => param?.code_melli || '-',
  },
  {
    key: 'phone',
    header: 'تلفن همراه',
    Component: ({ param }) => param?.phone || '-',
  },
  {
    key: 'shenasname_number',
    header: 'شماره شناسنامه',
    Component: ({ param }) => param?.shenasname_number || '-',
  },
  {
    key: 'id',
    header: 'کد شناسایی/ID',
    Component: ({ param }) => param?.id || '-',
  },
  {
    key: 'created_at',
    header: 'تاریخ ایجاد',
    Component: ({ param }) => fDate(param?.created_at) || '-',
  },

  {
    key: 'actions',
    header: '',
    Component: ({ param }) =>
      !disabled?.update || !disabled?.delete ? (
        <MoreTable>
          {!disabled?.update ? (
            <Link to={`${Enum.routes.root + Enum.routes.edit}/${param?.id}`}>
              <MoreTableItem isEdit />
            </Link>
          ) : (
            ''
          )}
          {!disabled?.delete ? <MoreTableItem isDelete onClick={() => onDelete(param)} /> : ''}
        </MoreTable>
      ) : (
        ''
      ),
    free: true,
  },
];
