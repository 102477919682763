import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const ERole = {
  title: {
    // name: ['عوامل', 'عوامل ها'],
  },
  // routes: PATH_DASHBOARD.agent,
  api: api.role,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default ERole;
