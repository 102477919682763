import RHFTextField from '../../../../../../../components/hook-form/RHFTextField';

const FormInputNumber = ({ name, data, disabled, options, ...props }) => {
  return (
    <RHFTextField
      type={'number'}
      name={name}
      label={data?.label}
      disabled={disabled}
      min={data?.options?.min}
      max={data?.options?.max}
      {...props}
    />
  );
};

export default FormInputNumber;
