import { useRef } from 'react';

const useDebounce = ({ setDebounce }) => {
  // Debounced function
  const timeoutId = useRef();

  const debounce = (value) => {
    clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      setDebounce(value);
    }, 500);
  };

  return {
    debounce,
  };
};
export default useDebounce;
