import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EVisitorRegistrations = {
  title: { name: ['ثبت نام ها', 'ثبت نام'] },
  routes: PATH_DASHBOARD.visitorRegistrations,
  api: api.travelRegister,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
  // permission: {
  //   read: 'TravelRegister read',
  //   update: 'TravelRegister update',
  //   create: 'TravelRegister create',
  //   delete: 'TravelRegister delete',
  //   confirm: 'TravelRegister confirm',
  // },
};

export default EVisitorRegistrations;
