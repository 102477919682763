import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, link, isLoading, logoImg }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box
      sx={{
        // width: 40, height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      {/* <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.4519 0.0236086C22.6421 0.105492 21.5526 0.433024 20.7804 0.826063C19.9706 1.23548 19.2944 1.73087 18.6266 2.40231C17.4453 3.59781 16.7524 4.96935 16.4351 6.75849C16.3976 6.9632 16.3725 8.04815 16.3558 10.5046C16.335 13.6121 16.3224 13.9847 16.2598 14.1689C16.1304 14.5456 15.8925 14.9386 15.6295 15.2088C15.4501 15.3931 14.8824 15.7861 14.7905 15.7861C14.7613 15.7861 14.6486 15.827 14.5359 15.8762L14.3314 15.9662L8.23715 15.9744C1.52933 15.9867 1.87578 15.9703 1.3081 16.2406C0.723719 16.519 0.210301 17.1617 0.0725546 17.7922C0.0349874 17.9601 0.0182909 19.5077 0.00576853 23.7902C-0.00675386 28.8915 -0.00257973 29.6121 0.0558581 29.9723C0.147689 30.5578 0.318828 30.8812 0.757112 31.2907C1.07017 31.5854 1.47506 31.8352 1.63785 31.8352C1.66707 31.8352 1.7756 31.8638 1.87578 31.8966C2.0344 31.9539 2.77739 31.9621 8.13698 31.9621C13.9975 31.9621 14.227 31.958 14.5025 31.8843C14.6611 31.8393 14.8824 31.7615 14.9909 31.7124C15.2664 31.5854 15.8007 31.0819 15.9885 30.7707C16.2891 30.2712 16.2724 30.6479 16.2849 23.9949L16.2974 18.0174L16.4059 17.7022C16.6939 16.8751 17.3535 16.2569 18.2175 16.0072C18.4387 15.9458 18.7935 15.9376 21.9617 15.9253C24.4161 15.9171 25.5097 15.8966 25.6349 15.8639C25.7309 15.8393 25.9522 15.7943 26.1233 15.7656C26.5866 15.6837 26.7619 15.6346 27.2712 15.4545C28.7864 14.914 30.0679 13.9765 31.0237 12.7196C31.6499 11.8926 31.9963 11.2006 32.3386 10.0952C32.468 9.66944 32.5389 9.26002 32.5974 8.58858C32.7226 7.1024 32.4179 5.57528 31.7375 4.28562C30.6982 2.31224 28.8741 0.850628 26.7077 0.252881C25.9689 0.0522676 25.7017 0.0195144 24.725 0.00313779C24.199 -0.00505052 23.623 0.00313779 23.4519 0.0236086Z"
          fill="#FF8E49"
        />
        <path
          d="M33.845 16.0277C33.4777 16.1137 33.1103 16.2857 32.814 16.519C32.5301 16.736 32.2004 17.2355 32.071 17.6408L31.9666 17.9602V23.8066C31.9666 30.3859 31.9792 30.0666 31.6661 30.6602C31.3489 31.258 30.7895 31.6961 30.0716 31.9131C29.7919 31.999 29.7209 31.999 26.4401 31.999C24.3906 31.999 23.0006 32.0154 22.8462 32.04C22.7084 32.0645 22.4705 32.1014 22.316 32.1219C21.7943 32.1956 20.8885 32.4862 20.2498 32.7769C18.9684 33.3624 17.7829 34.3818 16.9606 35.6019C15.4788 37.8045 15.2117 40.609 16.251 43.0737C16.6434 44.0113 17.1568 44.7769 17.8748 45.5016C18.5718 46.2058 19.0602 46.5783 19.8533 47.0205C20.4711 47.3603 21.5104 47.737 22.1157 47.8393C22.9505 47.9785 23.4055 48.0154 24.0149 47.9949C26.1354 47.9171 27.997 47.1433 29.4747 45.7186C30.848 44.4002 31.6828 42.7339 31.8831 40.9038C31.9124 40.6582 31.9374 39.2007 31.9458 37.2559L31.9625 34.0175L32.0626 33.7145C32.2213 33.26 32.3673 33.0349 32.718 32.695C33.0185 32.4044 33.2815 32.2447 33.7198 32.0768C33.8408 32.0277 34.9762 32.0154 40.0644 31.999L46.263 31.9786L46.597 31.8639C47.5111 31.5528 48.1414 30.8076 48.2457 29.9274C48.3 29.4402 48.3 18.5702 48.2457 18.038C48.1372 17.0349 47.3566 16.2161 46.3173 16.0114C46.0376 15.9581 45.2445 15.9499 40.077 15.954C34.5922 15.9581 34.133 15.9622 33.845 16.0277Z"
          fill="#5CF2A3"
        />
      </svg> */}
      <Box
        sx={{
          // backgroundColor: 'success.main',
          p: '3px 4px',
          borderRadius: '5px',
          img: {
            // minWidth: '135px',
          },
        }}
      >
        <img
          src={
            logoImg ||
            (isLoading
              ? '/icons/logo.svg'
              : // 'https://cdn.labbayk.ir/img/logo6.png'
                '/icons/logo.svg')
          }
          alt={'logo'}
        />
      </Box>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={link || '/'}>{logo}</RouterLink>;
}
