import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { travelTypeObject } from 'src/enumeration';
import api from 'src/services/api';
import { RHFUploadSingleFile } from 'src/components/hook-form';

import RHFTextField from '../../../components/hook-form/RHFTextField';
import FormProvider from '../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import axiosInstance from '../../../utils/axios';

const LotteryModalComponent = ({ onClose, setDisableBackdropClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.id;
  const navigate = useNavigate();

  const backUrl = `${Enum.routes.root}${Enum.routes.list}`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;

  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(api?.lottery?.base, params);
  const updating = (params) => axiosInstance.put(`${api?.lottery?.base}/${paramId}`, params);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = (res) => {
    console.log('* * * LotteryModalComponent :', { res });
    toast.success(t('successfully'));
    onClose();
    setDisableBackdropClose(false);

    // navigate(backUrl);
  };
  const onErrorMutating = (error) => {
    setDisableBackdropClose(false);
    console.log('* * * onErrorMutating :', { error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${api?.lottery?.base}_update`,
    invalidQuery: `${api.travel.base}_get_${queryParams.travel}`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------

  const onSubmit = async () => {
    const values = getValues();
    console.log('* * * onSubmit : ', { values });

    const formData = new FormData();
    const reqData = {
      [validation.fieldNames.file]: values[validation.fieldNames.file],
      travel_id: queryParams.travel,
    };
    Object.keys(reqData).forEach((key) => {
      formData.append(key, reqData[key]);
    });
    console.log('* * * onSubmit : ', { reqData, values });
    mutate(formData);
    setDisableBackdropClose(true);
  };

  console.log({ queryParams, onClose, setDisableBackdropClose });

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '550px',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
      >
        <Typography variant="h5">{'فایل سهمیه های قرعه کشی'}</Typography>
      </Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RHFUploadSingleFile
                accept=".xls,.xlsx"
                name={validation.fieldNames.file}
                title={'آپلود اکسل'}
                description={`
فایل را بکشید و رها کنید یا روی عکس کلیک کنید.
فرمت های قابل قبول: .xls .xlsx
`}
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          <LoadingButton
            // type="click"
            variant="outlined"
            color="success"
            disabled={isLoading}
            onClick={onClose}
          >
            {'بستن'}
          </LoadingButton>

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'آپلود ، قرعه کشی'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default LotteryModalComponent;
