import { InputAdornment } from '@mui/material';
import { useMemo } from 'react';

import RHFRadioGroups from '../../../../../../../components/hook-form/RHFRadioGroups';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputRadio = ({ name, data, disabled, ...props }) => {
  const options = useMemo(() => {
    // const param= JSON.parse(data?.options||'{}');
    return {
      array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      param: data?.options,
    };
  }, [data]);

  return (
    <RHFRadioGroups
      options={options?.array || []}
      name={name}
      label={data?.label}
      disabled={disabled}
      InputProps={
        data?.need_confirm
          ? {
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    display: 'flex',
                    gap: 1,
                  }}
                >
                  <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[0]} />
                </InputAdornment>
              ),
            }
          : ''
      }
      {...props}
    />
  );
};

export default FormInputRadio;
