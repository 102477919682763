import { useMemo, useRef } from 'react';
import { Button, Box, Typography, Grid } from '@mui/material';
import { ControlPoint, HighlightOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from '../../../Form/enum';
import axiosInstance from '../../../../utils/axios';
import { useMutationCustom, useQueryCustom } from '../../../../utils/reactQueryHooks';
import ModalLayout from '../../../../components/ModalLayout';
import api from '../../../../services/api';
import QuestionComponent from '../../../../components/ui/Question/index';
import RemoveNullObjectValue from '../../../../utils/RemoveNullObjectValue';

const Hypothetical = ({ section }) => {
  const ModalLayoutRemoveInputRef = useRef();
  const urlParams = useParams();
  const { t } = useTranslation();

  const getAllForms = async () =>
    axiosInstance.get(`${api.form.sample}`, {
      params: {
        section: section?.label,
        // page: 1,
        // perPage: 30,
        // search: 'کد',
      },
    });
  const addInputToSection = (params) => axiosInstance.post(Enum.api.sample, params);
  const deletingInput = (param) => axiosInstance.delete(`${api.input.base}/${param?.id}`);

  const handleRemoveInputModal = (params) => ModalLayoutRemoveInputRef.current.show((p) => !p, { data: params?.data });

  const formQuery = useQueryCustom({
    name: `${api.form.sample}_gets`,
    url: getAllForms,
    params: {
      section: section?.label,
    },
  });
  // ---------------------------------------

  const deleteInput = useMutationCustom({
    url: deletingInput,
    name: `${Enum?.api?.base}_delete`,
    // invalidQuery: `${Enum?.api?.base}_get_${urlParams?.id}`,
    invalidQueries: [`${Enum?.api?.base}_get_${urlParams?.id}`, `${Enum?.api?.base}_gets`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
      handleRemoveInputModal();
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');

      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  // ---------------------------------------

  const onSuccessMutating = () => {
    toast.success(t('successfully'));
  };

  const onErrorMutating = (error) => {
    console.log({ error });
    const errorTitle = error.response.data.message || t('errorTryAgain');

    const errors = Object.values(error?.response?.data?.errors || {});
    if (errors?.length) {
      errors?.map((x) => {
        return toast.error(x?.[0]);
      });
    } else toast.error(errorTitle);
  };

  const { isLoading, mutate } = useMutationCustom({
    url: addInputToSection,
    name: `${Enum?.api?.base}_add_input`,
    // invalidQuery: `${Enum?.api?.base}_get_${urlParams?.id}`,
    invalidQueries: [`${Enum?.api?.base}_get_${urlParams?.id}`, `${Enum?.api?.base}_gets`],

    // invalidQueries: [`${Enum?.api?.base}_get_${urlParams?.id}`, `${Enum?.api?.base}_gets`],
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });

  const handleAdd = (data) => {
    console.log({ data });

    // const formData = new FormData();
    // const pretty = RemoveNullObjectValue({
    //   image: data?.image,
    //   label: data?.label,
    //   options: data?.options,
    //   type: data?.type,
    //   form_section_id: section?.id,
    // });
    // Object.keys(pretty).forEach((key) => {
    //   formData.append(key, pretty[key]);
    // });

    // mutate(formData);
    mutate({
      form_section_id: section?.id,
      form_input_id: data?.id,
    });
  };

  const handleDelete = (data) => {
    console.log('* * * handleDelete : ', { data });
    deleteInput.mutate(data);
  };

  const inputsValid = useMemo(() => {
    return formQuery?.data?.data?.filter((x) => !section.inputs?.some((y) => x.id === y.id || x.label === y.label));
  }, [formQuery, section]);

  console.log({ inputsValid, formQuery, section, inputs: section.inputs });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
      }}
      container
      spacing={1}
    >
      <ModalLayout ref={ModalLayoutRemoveInputRef}>
        <QuestionComponent
          {...{
            loading: deleteInput.isLoading,
            title: t('question.delete.title'),
            description: t('question.delete.description'),
            button: {
              confirm: {
                label: t('question.delete.yes'),
                onClick: (_, data) => handleDelete(data),
              },
              reject: {
                label: t('question.delete.no'),
                onClick: () => handleRemoveInputModal(),
              },
            },
          }}
          onClose={() => handleRemoveInputModal()}
        />
      </ModalLayout>
      {
        inputsValid?.length
          ? inputsValid?.map((x) => (
              <Box key={`items-${x.id}`} item xs={6} md={3}>
                <Button
                  // onClick={handleToggleInputModal}
                  variant="contained"
                  color="grey"
                  disabled={isLoading}
                  // border={'1px solid'}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '300px',
                    }}
                    onClick={() => handleAdd(x)}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                      }}
                      noWrap
                    >
                      {x?.label}
                    </Typography>
                    <ControlPoint
                      fontSize="13px"
                      // sx={{
                      //   color: '#000',
                      // }}
                    />
                    {/* <Button
                      sx={{
                        m: '0 !important',
                        p: '0 !important',
                        minWidth: 0,
                        color: 'inherit',
                        '&:hover': {
                          color: 'success.main',
                        },
                      }}
                    
                    >
                      <ControlPoint
                      // sx={{
                      //   color: '#000',
                      // }}
                      />
                    </Button> */}

                    {/* <Button
                      sx={{
                        m: '0 !important',
                        p: '0 !important',
                        minWidt
h: 0,
                        color: 'inherit',
                        '&:hover': {
                          color: 'warning.dark',
                        },
                      }}
                      onClick={() => handleRemoveInputModal({ data: x })}
                    >
                      <HighlightOff
                      // sx={{
                      //   color: '#000',
                      // }}
                      />
                    </Button> */}
                  </Box>
                </Button>
              </Box>
            ))
          : ''
        // <Grid item xs={12}>
        //   <Alert severity="info">{'موردی یافت نشد!'}</Alert>
        // </Grid>
      }
    </Box>
  );
};

export default Hypothetical;
