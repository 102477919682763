/* eslint-disable */
import { useState } from 'react';
// @mui
import AcceptOrRejectWithQuestionsWithReason from 'src/pages/profiles/Show/Travels/ById/Section/AcceptOrRejectWithQuestionsWithReason';
import { Box, Typography, Stack } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import isString from 'lodash/isString';
// assets
import { UploadIllustration } from '../../assets';
import Image from '../Image';
import LightboxModal from '../LightboxModal';
// ----------------------------------------------------------------------
const checkFileType = (url) => {
  const fileExtension = url?.split?.('.')?.pop()?.toLocaleLowerCase();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
  const videoExtensions = ['mp4', 'avi', 'mov', 'mkv'];
  const documentExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];

  if (imageExtensions.includes(fileExtension)) {
    return { isImage: true }; // ('File type: Image');
  } else if (videoExtensions.includes(fileExtension)) {
    return { isVideo: true }; //('File type: Video');
  } else if (documentExtensions.includes(fileExtension)) {
    return { isDocument: true }; //('File type: Document');
  } else {
    return false;
  }
};

export default function BlockContent({ title, description, file, absolute }) {
  let src = isString(file) ? file : file?.preview;
  const isImage = file?.type?.includes('image') || checkFileType(src)?.isImage;

  const [showModal, setShowModal] = useState();
  // console.log('* * * BlockContent :', { showModal, file, isImage });

  return (
    <Stack
      spacing={2}
      alignItems="center"
      // justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      {file ? (
        !isImage ? (
          <Box
            sx={{
              display: 'flex',
              // whiteSpace: 'nowrap',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ArticleIcon sx={{ fontSize: '50px' }} />
            <Typography>{file?.name}</Typography>
          </Box>
        ) : (
          <>
            {/* <LightboxModal
              onClose={() => setShowModal((p) => !p)}
              photoIndex={0}
              isOpen={showModal}
              images={[isString(file) ? file : file.preview]}
            /> */}
            <Image
              alt="file preview"
              src={isString(file) ? file : file.preview}
              onClick={() => absolute && setShowModal((p) => !p)}
              sx={{
                top: 8,
                left: 8,
                borderRadius: 1,
                // position: 'absolute',
                // width: 'calc(100% - 16px)',
                // height: 'calc(100% - 16px)',
                ...(absolute ? { width: '280px', height: '280px' } : { width: '220px', height: '220px' }),
              }}
            />
          </>
        )
      ) : (
        <UploadIllustration sx={{ width: 220 }} />
      )}

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {/* Drop or Select file */}
          {title || 'تصویر ایکون مورد نظرتان را آپلود کنید.'}
        </Typography>
        {absolute ? (
          absolute || ''
        ) : (
          <>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {description ||
                `فایل را بکشید و رها کنید یا روی دکمه انتخاب فایل کلیک کنید.
            حداکثر حجم قابل قبول برای هر عکس: 5 مگابایت
            فرمت های قابل قبول: gif، JPG، PNG،`}
            </Typography>
          </>
        )}

        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Drop files here or click&nbsp;
          <Typography
            variant="body2"
            component="span"
            sx={{ color: 'primary.main', textDecoration: 'underline' }}
          >
            browse
          </Typography>
          &nbsp;thorough your machine
        </Typography> */}
      </Box>
    </Stack>
  );
}
