import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const EUniversityCategory = {
  title: {
    name: ['دسته دانشگاه', 'دسته دانشگاه ها'],
  },
  routes: PATH_DASHBOARD.universityCategory,
  api: api.universityCategory,
  enum: {
    array: Object.values(travelTypeObject),
    object: travelTypeObject,
  },
};

export default EUniversityCategory;
