import * as yup from 'yup';
import errorsText from '../../../../utils/errorsText';

const fieldNames = {
  label: 'label',
  type: 'type',
  startDate: 'registration_start_at',
  endDate: 'registration_finish_at',
  options: 'options',
  min: 'min',
  max: 'max',
  items: 'items',
  link: 'link',
  image: 'image',
  required: 'required',
  locationType: 'locationType',
  selectType: 'selectType',
  needConfirm: 'need_confirm',
  registerConfirm: 'register_confirm',
};

const items = yup
  .object({
    [fieldNames.label]: yup.string().nullable().required(errorsText.blankError()),
  })
  .defined();

const validationTypes = {
  NUMBER: {
    [fieldNames.min]: yup.number().nullable().required(errorsText.blankError()).typeError(errorsText.blankError()),
    [fieldNames.max]: yup.number().nullable().required(errorsText.blankError()).typeError(errorsText.blankError()),
  },
  SELECT: {
    [fieldNames.selectType]: yup.mixed().nullable(),
    [fieldNames.items]: yup
      .array()
      .of(items)
      .when(fieldNames.selectType, {
        is: (data) => {
          console.log('* * * onSubmit : ', { data }, !!data?.value);
          return !data?.value;
        },
        then: (schema) => schema.min(1, errorsText.appendAnswer()).required(errorsText.appendAnswer()),
        otherwise: (schema) => schema,
      }),
    // [fieldNames.items]: yup.array().of(items).min(1, errorsText.appendAnswer()).required(errorsText.appendAnswer()),
  },
  CHECKBOX: {
    [fieldNames.items]: yup.array().of(items).min(1, errorsText.appendAnswer()).required(errorsText.appendAnswer()),
  },
  RADIO: {
    [fieldNames.items]: yup.array().of(items).min(1, errorsText.appendAnswer()).required(errorsText.appendAnswer()),
  },
  SOCIAL_MEDIA: {
    [fieldNames.link]: yup.string().nullable().required(errorsText.blankError()),
    [fieldNames.image]: yup.mixed().required(errorsText.blankError()),
  },
  LOCATION: {
    [fieldNames.locationType]: yup.mixed().required(errorsText.blankError()),
  },
};

const validation = {
  fieldNames,
  schema: (type) =>
    yup.object().shape({
      [fieldNames.type]: yup.object().nullable().required(errorsText.blankError()),
      [fieldNames.label]: yup.string().nullable().required(errorsText.blankError()),
      ...validationTypes[type],
    }),
};

export default validation;
