const errorsText = {
  authorIsRequire: (author) => `${author} اجباری میباشد`,
  authorIsNotValid: (author) => `${author} معتبر نیست`,
  blankError: (author) => `لطفا این قسمت را خالی نگذارید`,
  keyboardError: (author) => `لطفا برای وارد کیبورد خود را انگلیسی کنید`,
  appendAnswer: (author) => `لطفا یک جواب اضافه نمایید`,
  pleaseEnterYourAuthor: (author) => `لطفاً ${author} خود را وارد کنید`,
  pleaseEnterCodeSent: (author) => `لطفا کد ارسال شده را وارد نمایید`,
  pleaseEnterAuthCode: (author) => `لطفا کد تایید را وارد کنید`,
  pleaseEnterYourAuthorOr: (author, another) => `لطفاً ${author} یا ${another} خود را وارد کنید`,
  invalidEmailOrMobileNumber: (author) => `ایمیل یا شماره موبایل وارد شده صحیح نمیباشد`,
  invalidValue: (author) => `${author} وارد شده صحیح نمیباشد`,
  mustBe: (author, kind) => `${author} باید ${kind} باشد`,
  pleaseSelectYour: (author) => `لطفاً ${author} خود را انتخاب کنید`,
  email: (author) => `ایمیل`,
  mobile: (author) => `موبایل`,
  mobileNumber: (author) => `شماره موبایل`,
  confirmationCodeToNumberAuthor: (author) => `کد تایید برای شماره ${author} پیامک شد`,
  confirmationCodeToEmailAuthor: (author) => `کد تایید برای ایمیل ${author} ارسال شد`,
  remainingCode: (author) => `دریافت مجدد کد ${author}`,
  getCodeAgain: (author) => `دریافت مجدد کد`,
  dateIsLast: () => `این تاریج گذشته است`,
  startDateIsGreater: () => `تاریخ پایان کوچک تر از تاریخ شروغ است`,
  minMax: (min, max) => (min === max ? ` باید ${min} رقمی باشد` : `شماره وارد شده باید بین ${min} تا ${max} باشد`),
  min: (min) => ` کمترین مقدار ${min} می باشد`,
  max: (max) => ` بیشترین مقدار ${max} می باشد`,
  passwordNotMatch: () => `رمز عبور ها برابر نیست`,
};
export default errorsText;
