import { Box, Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import validation from '../validation';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';

const AddableFormMarried = () => {
  return (
    <Box>
      <Typography sx={{ my: 3 }}>{'انتخاب بر اساس متاهل بودن و جنسیت'}</Typography>
      <Typography sx={{ my: 3, fontWeight: '300', fontSize: '14px', color: 'grey.600' }}>
        {'یک بازه سنی مشخص کنید سپس درصد را وارد کنید.'}
      </Typography>

      <Box sx={{}}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <RHFTextField name={`${validation.fieldNames.hhh}`} label={'مجرد آقا'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <RHFTextField name={`${validation.fieldNames.iii}`} label={'مجرد خانم'} />
          </Grid>
          <Grid item xs={12} md={4}>
            <RHFTextField name={`${validation.fieldNames.jjj}`} label={'متاهل'} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AddableFormMarried;
