import { ArrowBack } from '@mui/icons-material';
import { useMemo } from 'react';

// routes
import { PUBLIC_PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

import axiosInstance from '../../../utils/axios';
import EForms from '../../../pages/Form/enum';
import { travelTypeObject } from '../../../enumeration/index';

import {
  adminPermission,
  newsAgencyPermission,
  rolePermission,
  sellerPermission,
  ticketPermission,
  transactionPermission,
} from '../../../permission';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('team'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  tournament: getIcon('tournament'),
  support: getIcon('support'),
  building: getIcon('building'),
};

const useNavConfigPublic = () => {
  const { isAuthenticated } = useAuth();

  const getting = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(EForms.api.base, {
      params: {
        type: params.type,
        is_active: 1,
      },
    });
  };

  const gettingHistorySide = async () => {
    return axiosInstance.get(api.client.travel.base, {});
  };

  const sideQuery = useQueryCustom({
    name: `${api.client.travel.base}_get`,
    url: gettingHistorySide,
  });
  console.log({ sideQuery });

  const gettingSide = async () => {
    return axiosInstance.get(api.public.travel.base, {});
  };

  // const sideQuery = useQueryCustom({
  //   name: `${api.public.travel.base}_get`,
  //   url: gettingSide,
  // });

  const sideTabs = sideQuery?.data?.data;
  // useMemo(() => {
  //   const tab = {
  //     [travelTypeObject.omre.value]: [],
  //     [travelTypeObject.atabat_aliat.value]: [],
  //     [travelTypeObject.other.value]: [],
  //     [travelTypeObject.tamato.value]: [],
  //   };

  //   if (sideQuery?.data?.data?.length) {
  //     for (let i = 0; i < sideQuery?.data?.data?.length; i += 1) {
  //       const curr = sideQuery?.data?.data?.[i];
  //       tab[curr.type] = tab[curr.type] || [];
  //       tab[curr.type] = curr.travels;
  //     }
  //   }
  //   return tab;
  // }, [sideQuery]);

  const atabatAliatTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.atabat_aliat.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PUBLIC_PATH_DASHBOARD.form.root(travelTypeObject.atabat_aliat.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PUBLIC_PATH_DASHBOARD.atabatAliat.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.atabat_aliat.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const omreTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.omre.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PUBLIC_PATH_DASHBOARD.form.root(travelTypeObject.omre.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PUBLIC_PATH_DASHBOARD.omre.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.omre.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const tamatoTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.tamato.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PUBLIC_PATH_DASHBOARD.form.root(travelTypeObject.tamato.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PUBLIC_PATH_DASHBOARD.tamato.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.tamato.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const otherTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.other.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PUBLIC_PATH_DASHBOARD.form.root(travelTypeObject.other.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PUBLIC_PATH_DASHBOARD.other.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.other.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  // console.log({ omreTab, sideTabs });

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'داشبورد',
    //   items: [
    //     { title: 'اعلانات', path: PUBLIC_PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    // { title: 'اخبار', path: PUBLIC_PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    // { title: 'e-commerce', path: PUBLIC_PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
    // { title: 'analytics', path: PUBLIC_PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    // { title: 'banking', path: PUBLIC_PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    // { title: 'booking', path: PUBLIC_PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    //   ],
    // },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      // subheader: '',
      items: [
        // {
        //   title: 'عناوین',
        //   path: PUBLIC_PATH_DASHBOARD.general.app,
        //   // icon: ICONS.dashboard,
        //   // isCollapse: true,
        // },
        {
          title: 'حج عمره',
          path: PUBLIC_PATH_DASHBOARD.omre.root,
          children: [
            ...omreTab,
            // {
            //   title: 'سوابق',
            //   path: PUBLIC_PATH_DASHBOARD.records.root(travelTypeObject.omre.value),
            // },
          ],
        },
        {
          title: 'حج تمتع',
          path: PUBLIC_PATH_DASHBOARD.tamato.root,
          // icon: ICONS.analytics,
          children: [
            ...tamatoTab,
            // {
            //   title: 'سوابق',
            //   path: PUBLIC_PATH_DASHBOARD.records.root(travelTypeObject.tamato.value),
            // },
          ],
        },
        {
          title: 'عتبات عالیات',
          path: PUBLIC_PATH_DASHBOARD.atabatAliat.list,
          children: [
            ...atabatAliatTab,
            // {
            //   title: 'سوابق',
            //   path: PUBLIC_PATH_DASHBOARD.records.root(travelTypeObject.atabat_aliat.value),
            // },
          ],
        },
        {
          title: 'سفرهای زیارتی دیگر',
          path: PUBLIC_PATH_DASHBOARD.other.list,
          // icon: ICONS.team,
          children: [
            ...otherTab,
            // {
            //   title: 'سوابق',
            //   path: PUBLIC_PATH_DASHBOARD.records.root(travelTypeObject.other.value),
            // },
          ],
        },
      ],
    },
  ];
};

export default useNavConfigPublic;
