import * as yup from 'yup';
import errorsText from '../../../utils/errorsText';

const validation = {
  // defaultValue:{},
  fieldNames: {
    name: 'name',
    order: 'order',
    startDate: 'registration_start_at',
    endDate: 'registration_finish_at',
  },
  schema: () =>
    yup.object().shape({
      [validation.fieldNames.order]: yup.object().required(errorsText.blankError()),
      [validation.fieldNames.name]: yup.string().required(errorsText.blankError()),
    }),
};

export default validation;
