import { Remove } from '@mui/icons-material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalLayout from '../../../../components/ModalLayout';
import QuestionComponent from '../../../../components/ui/Question/index';
import { useMutationCustom } from '../../../../utils/reactQueryHooks';
import Enum from '../../enum';
import axiosInstance from '../../../../utils/axios';
import api from '../../../../services/api';
import { PATH_DASHBOARD } from '../../../../routes/paths';

const FormSections = ({ data, handleEdit, refetch, sample, disabled }) => {
  const { t } = useTranslation();
  const urlParams = useParams();
  const queryClient = useQueryClient();
  const ModalLayoutRemoveInputSectionRef = useRef();
  const handleRemoveInputSectionModal = (params) =>
    ModalLayoutRemoveInputSectionRef.current.show((p) => !p, { data: params?.data });

  // ------------------------------------------------------------------------------ Mutation service

  const deletingInputSection = (param) => axiosInstance.delete(`${Enum?.api?.base}/${param?.id}`);

  const deleteInput = useMutationCustom({
    url: deletingInputSection,
    name: `${Enum?.api?.base}_delete`,
    invalidQuery: `${api.travel.base}_get_${urlParams?.travel}`,
    // invalidQueries: [`${api.travel.base}_get_${urlParams?.travel}`],
    onSuccess: () => {
      toast.success(t('question.delete.successfully'));
      handleRemoveInputSectionModal();
      // queryClient.invalidateQueries(`${api.travel.base}_get_${urlParams?.travel}`);
      refetch();
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');
      // queryClient.invalidateQueries(`${api.travel.base}_get_${urlParams?.travel}`);
      refetch();
      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const handleDelete = (data) => {
    console.log('* * * handleDelete : ', { data });
    deleteInput.mutate(data);
  };
  // ------------------------------------------------------------------------------ Mutation service

  const useInputSection = (body) => axiosInstance.post(`${api.createFormBySample.base}`, body);

  const userForm = useMutationCustom({
    url: useInputSection,
    name: `${api.createFormBySample.base}_post`,
    invalidQuery: `${api.travel.base}_get_${urlParams?.travel}`,
    // invalidQueries: [`${api.travel.base}_get_${urlParams?.travel}`],
    onSuccess: () => {
      toast.success(t('successfully'));
      // refetch();
    },
    onError: (error) => {
      console.log({ error });
      const errorTitle = error.response.data.message || t('errorTryAgain');
      // queryClient.invalidateQueries(`${api.travel.base}_get_${urlParams?.travel}`);
      refetch();
      const errors = Object.values(error?.response?.data?.errors || {});
      if (errors?.length) {
        errors?.map((x) => {
          return toast.error(x?.[0]);
        });
      } else toast.error(errorTitle);
    },
  });
  const handleUse = () => {
    userForm.mutate({
      form_id: data?.id,
      travel_id: urlParams?.travel,
    });
  };
  // console.log({ urlParams, aa: urlParams?.travel });
  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: '#E0E0E0',
        px: 2,
        py: 3,
        background: '#FCFCFC',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '2px',
      }}
    >
      <ModalLayout ref={ModalLayoutRemoveInputSectionRef}>
        <QuestionComponent
          {...{
            loading: deleteInput.isLoading,
            title: t('question.delete.title'),
            description: t('question.delete.description'),
            button: {
              confirm: {
                label: t('question.delete.yes'),
                onClick: (_, data) => handleDelete(data),
              },
              reject: {
                label: t('question.delete.no'),
                onClick: () => handleRemoveInputSectionModal(),
              },
            },
          }}
          onClose={handleRemoveInputSectionModal}
        />
      </ModalLayout>

      <Typography>{data?.name}</Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 3,
        }}
      >
        {!sample ? (
          <>
            {!disabled?.delete ? (
              <Button
                variant="outlined"
                color="error"
                sx={{ display: 'flex', gap: 1 }}
                onClick={() => handleRemoveInputSectionModal({ data })}
              >
                <Box>{'حذف'}</Box>
                <DeleteOutlineIcon fontSize="13px" />
              </Button>
            ) : (
              ''
            )}
            {!disabled?.edit ? (
              <Button variant="outlined" color="success" sx={{ display: 'flex', gap: 1 }} onClick={handleEdit}>
                <Box>{'ویرایش'}</Box>
                <EditOutlinedIcon fontSize="13px" />
              </Button>
            ) : (
              ''
            )}

            {!disabled?.show ? (
              <Link to={`${PATH_DASHBOARD.form.root(urlParams.type, urlParams.travel)}/${data?.id}`}>
                <Button variant="outlined" color="primary" sx={{ display: 'flex', gap: 1 }} onClick={handleEdit}>
                  <Box>{'مشاهده'}</Box>
                  <RemoveRedEyeOutlinedIcon fontSize="13px" />
                </Button>
              </Link>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            {!disabled?.edit ? (
              <LoadingButton
                loading={userForm.isLoading}
                variant="outlined"
                color="success"
                sx={{ display: 'flex', gap: 1 }}
                onClick={handleUse}
              >
                <Box>{'استفاده از فرم'}</Box>
                <ControlPointIcon fontSize="15px" />
              </LoadingButton>
            ) : (
              ''
            )}

            <Link to={`${PATH_DASHBOARD.form.root(urlParams.type, urlParams.travel)}/${data?.id}/sample`}>
              <Button variant="outlined" color="primary" sx={{ display: 'flex', gap: 1 }} onClick={handleEdit}>
                <Box>{'مشاهده'}</Box>
                <RemoveRedEyeOutlinedIcon fontSize="13px" />
              </Button>
            </Link>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FormSections;
