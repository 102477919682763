import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { adminProfile, clientProfile } from '../../services/admin';

// Actions

// ----------------------------------------------------------------------

export const getAdminProfile = createAsyncThunk('auth/get-admin', async (_, { rejectWithValue, getState }) => {
  // dispatch(slice.actions.startLoading());
  try {
    const resData = await adminProfile();
    // dispatch(slice.actions.getUserData(data?.data));
    return resData?.data;
  } catch (error) {
    // dispatch(slice.actions.hasError(error));
    console.log(error);
    rejectWithValue(error);
  }
});
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  data: {},
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = true;
    },

    // INIT
    getUserData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAdminProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAdminProfile.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAdminProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

// Reducer
export default slice.reducer;
