import { Route, Routes } from 'react-router';
import Enum from '../../enum';
import List from './List';

const SpecificationComponent = (props) => {
  // <div>{'SpecificationComponent'}</div>
  return (
    <Routes>
      <Route path={`/*`} element={<List {...props} />} />
    </Routes>
  );
};

export default SpecificationComponent;
