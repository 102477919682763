import { PUBLIC_PATH_DASHBOARD } from '../../../routes/paths';
import api from '../../../services/api';

const EPublicForm = {
  title: '',
  bseName:"a_",
  routes: PUBLIC_PATH_DASHBOARD.form,
  api: api.public.form,
};

export default EPublicForm;
