import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, MenuItem } from '@mui/material';
import { useRef } from 'react';
import ModalLayout from '../../ModalLayout';
import QuestionComponent from '../../ui/Question/index';

const questionsText = {
  delete: {
    title: 'question.delete.title',
    description: 'question.delete.description',
    button: {
      confirm: {
        label: 'question.delete.yes',
      },
      reject: {
        label: 'question.delete.no',
      },
    },
  },
};

const MoreTableItem = ({
  icon,
  iconSx,
  label,
  isDelete,
  isEdit,
  sx,
  question,
  onClick,
  loading,
  disabled,
  ...props
}) => {
  const ModalLayoutRemoveInputRef = useRef();

  const handleQuestionModal = (params) => ModalLayoutRemoveInputRef.current.show((p) => !p, params);

  const handleClick = () => {
    if (question) {
      handleQuestionModal(question);
    } else if (isDelete) {
      const newQuestion = { ...questionsText.delete };
      newQuestion.button.confirm.onClick = onClick;
      handleQuestionModal(questionsText.delete);
    }
  };

  return (
    <MenuItem
      sx={{
        display: 'flex',
        gap: 3,
        alignItems: 'center',
        justifyContent: 'center',
        color: 'grey.800',
        width: 'auto',

        //   "&:hover":{
        //     color
        //   },
        ...sx,
      }}
      onClick={handleClick}
      {...props}
    >
      <ModalLayout ref={ModalLayoutRemoveInputRef}>
        <QuestionComponent
          {...{
            loading,
            button: {
              confirm: {
                label: 'question.accept',
                onClick: (_, data) => onClick(data),
              },
              reject: {
                label: 'question.no',
                onClick: () => handleQuestionModal(),
              },
            },
            // ...question,
            // title: t('question.delete.title'),
            // description: t('question.delete.description'),
            // button: {
            //   confirm: {
            //     label: t('question.delete.yes'),
            //     onClick: (_, data) => handleDelete(data),
            //   },
            //   reject: {
            //     label: t('question.delete.no'),
            //     onClick: () => handleQuestionModal(),
            //   },
            // },
          }}
          onClose={() => handleQuestionModal()}
        />
      </ModalLayout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'center',

          '&>svg': {
            fontSize: '18px',
          },
          ...iconSx,
        }}
      >
        {isDelete ? <DeleteIcon /> : isEdit ? <EditIcon /> : icon}
      </Box>
      {!disabled?.label ? <Box>{isDelete ? 'حذف' : isEdit ? 'ویرایش' : label}</Box> : ''}
    </MenuItem>
  );
};

export default MoreTableItem;
