import { Link, NavLink, useLocation, useNavigate, useParams, useSearchParams, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Enum from '../enum';
// import useSettings from '../../../hooks/useSettings';
// import useQueryString from '../../../utils/useQueryString';
import AgentComponent from './Agent';
import SocialComponent from './Social';
import ShowCaravansManagementLayout from './Layout';
import CaravansManagementShowMessage from './Messages';
import CaravansManagementShowNotification from './notifications';
import CaravansManagementShowBank from './Bank';
import ProfileAccess from './Access';
import SpecificationComponent from './Specification';
import TravelsComponent from './Travels';
import axiosInstance from 'src/utils/axios';
import { useQueryCustom } from 'src/utils/reactQueryHooks';

const ShowCaravansManagement = () => {
  const { t } = useTranslation();
  const queryParams = useParams();
  console.log({ queryParams });
  // const queryString = useQueryString();
  // const location = useLocation();
  // const { themeStretch } = useSettings();
  // const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}`;
  // const paramId = queryParams?.id;

  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${queryParams?.id}`);

  const dataById = useQueryCustom({
    name: `get_by_Id_${Enum?.api?.base}_${queryParams?.id}`,
    url: getById,
    enabled: !!queryParams?.id,
  });

  const user = dataById?.data?.data?.client;
  const Component = {
    [Enum.enumTab.object.specification.value]: <SpecificationComponent {...{ user }} />,
    [Enum.enumTab.object.travels.value]: <TravelsComponent {...{ user }} />,
    [Enum.enumTab.object.access.value]: <ProfileAccess {...{ user }} />,
    [Enum.enumTab.object.message.value]: <CaravansManagementShowNotification {...{ user }} />,
    [Enum.enumTab.object.bank.value]: <CaravansManagementShowBank {...{ user }} />,
  };

  return <>{Component[queryParams.tab] || <ShowCaravansManagementLayout {...{ user }} />}</>;
  // <Routes>
  //   <Route path={`/:type`} element={<ShowCaravansManagement />} />
  // </Routes>
};

export default ShowCaravansManagement;
