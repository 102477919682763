import { travelTypeObject } from '../../enumeration';
import { PATH_DASHBOARD } from '../../routes/paths';
import api from '../../services/api';

const caravanTabType = {
  client: { label: 'زائرین', value: 'client' },
  agent: { label: 'عوامل', value: 'agent' },
  nezam: { label: 'نظام وظیفه', value: 'nezam' },
  bank: { label: 'بانک', value: 'bank' },
  message: { label: 'پیام ها', value: 'message' },
  social: { label: 'شبکه اجتماعی', value: 'social' },
  tutorial: { label: 'آموزش و آزمون', value: 'tutorial' },
  more: { label: 'سایر امکانات', value: 'more' },
};

const ECaravan = {
  title: {
    name: ['کاروان', 'کاروان ها'],
  },
  routes: PATH_DASHBOARD.caravansManagement,
  api: api.caravan,
  // enum: {
  //   array: Object.values(caravanTabType),
  //   object: caravanTabType,
  // },
  enumTab: {
    array: Object.values(caravanTabType),
    object: caravanTabType,
  },
};

export default ECaravan;
