import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Box, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioGroups.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
};

export default function RHFRadioGroups({
  label,
  name,
  options,
  defaultValue,
  defaultChecked,
  showing,
  absolute,
  disableForm,
  onChange,
  value,
  ...props
}) {
  const { control } = useFormContext() || {};

  return disableForm ? (
    <FormControl fullWidth {...props}>
      <FormLabel
        sx={{
          borderBottom: '1px solid',
          borderColor: 'grey.200',
          pb: 1,
        }}
        id="demo-row-radio-buttons-group-label"
      >
        {label}
      </FormLabel>
      <RadioGroup
        defaultValue={defaultValue}
        defaultChecked={defaultChecked}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        sx={{
          ...(absolute && { pointerEvents: 'none' }),
        }}
      >
        {options.map((x) => (
          <FormControlLabel
            key={x.value}
            value={x.value}
            checked={x.value === value || x.value === value?.value}
            onChange={(e) => onChange(x)}
            control={<Radio color={'success'} />}
            label={x.label}
            disabled={x.disabled}
            sx={{
              color: 'grey.600',
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  ) : (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        showing ? (
          <Box>
            {label && (
              <Box
                className={[
                  'flex items-center  mb-2',
                  // error ? 'text-error-primary' : '',
                  // disabled ? 'opacity-50' : '',
                ].join(' ')}
              >
                <label htmlFor={name} className="leading-[20px] min-h-[20px] text-[14px] ">
                  {label}
                </label>
                {/* {required ? <span className="text-primary-main ml-2">{' *'}</span> : ''} */}
              </Box>
            )}
            <Box
              sx={{
                py: 2,
              }}
            >
              {field?.value?.label || '-'}
            </Box>
          </Box>
        ) : (
          <div>
            <FormControl error={error} fullWidth {...props}>
              <FormLabel
                sx={{
                  borderBottom: '1px solid',
                  borderColor: 'grey.200',
                  pb: 1,
                }}
                id="demo-row-radio-buttons-group-label"
              >
                {label}
              </FormLabel>
              <RadioGroup
                defaultValue={defaultValue}
                defaultChecked={defaultChecked}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  ...(absolute && { pointerEvents: 'none' }),
                }}
              >
                {options.map((x) => (
                  <FormControlLabel
                    key={x.value}
                    value={x.value}
                    checked={x.value === field.value || x.value === field.value?.value}
                    onChange={(e) => field.onChange(x)}
                    control={<Radio color={'success'} />}
                    label={x.label}
                    sx={{
                      color: 'grey.600',
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            {!!error && (
              <FormHelperText error sx={{ px: 2 }}>
                {error.message}
              </FormHelperText>
            )}
          </div>
        )
      }
    />
  );
}
