import { Box, FormHelperText, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import persianToEnglishNumber from 'src/utils/persianToEnglishNumber';
import { useTranslation } from 'react-i18next';
import RoleSelector from 'src/pages/role/selector';
import CaravanSelector from 'src/pages/caravansManagement/selector';
import ProfileSelector from 'src/pages/profiles/selector';
import RHFTextField from '../../../../../components/hook-form/RHFTextField';
import FormProvider from '../../../../../components/hook-form/FormProvider';
import validation from './validation';
import Enum from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../../../utils/reactQueryHooks';
import axiosInstance from '../../../../../utils/axios';
import HeaderBreadcrumbs from '../../../../../components/HeaderBreadcrumbs';
import WaitingBox from '../../../../../components/WaitingBox/index';
import RHFTextareaField from '../../../../../components/hook-form/RHFTextareaField';
import RHFSelector from '../../../../../components/hook-form/RHFSelector';
import EnumBase from '../../../enum';

const Add = ({ onClose }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const paramId = queryParams?.paramId;
  const caravanId = queryParams?.id;

  const navigate = useNavigate();
  const backUrl = `${Enum.routes.root(queryParams.type, queryParams.travel)}/show/${queryParams.id}/${
    EnumBase.enumTab.object.message.value
  }`;
  const methods = useForm({
    resolver: yupResolver(validation.schema()),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = methods;
  console.log({ errors, aa: errors?.all });
  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(Enum?.api?.base, params);
  const updating = (params) => axiosInstance.put(`${Enum?.api?.base}/${paramId}`, params);
  const getById = () => axiosInstance.get(`${Enum?.api?.base}/${paramId}`);
  // ------------------------------------------------------------------------------ Mutation
  const onSuccessMutating = () => {
    toast.success(t('successfully'));
    navigate(backUrl);
  };
  // const onErrorMutating = (error) => {
  //   console.log('* * * onErrorMutating :', { error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');
  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };
  const { isLoading, mutate } = useMutationCustom({
    url: paramId ? updating : creating,
    name: `${Enum?.api?.base}_update`,
    invalidQuery: `${Enum?.api?.base}_get`,
    onSuccess: onSuccessMutating,
    // onError: onErrorMutating,
  });
  // ---------------------------------------
  // const onSuccess = (resData) => {
  //   console.log('* * * onSuccess', { resData });
  //   const resetData = {
  //     [validation.fieldNames.send_to]: resData?.data?.[validation.fieldNames.send_to] || '',
  //     [validation.fieldNames.message]: resData?.data?.[validation.fieldNames.message] || '',
  //     // [validation.fieldNames.social]: socialSelector.convertor.object(resData?.data?.social),
  //   };
  //   console.log('* * * onSuccess', { resetData });
  //   reset(resetData);
  // };
  // const dataById = useQueryCustom({
  //   name: `getById_${Enum?.api?.social}_${paramId}`,
  //   url: getById,
  //   onSuccess: onSuccess,
  //   enabled: !!paramId,
  // });
  // console.log({ watchStartDate });
  const onSubmit = async () => {
    const values = getValues();
    // console.log('* * * onSubmit : ', { isValid, inValidPhones });

    // if (!(values[validation.fieldNames.team]?.value || values[validation.fieldNames.clients]?.length)) {
    //   return setError(
    //     'all',
    //     { message: 'لطفا یکی از بخش های کاروان یا کاربران را تکمیل نمایید و سپس بر روی دکمه ثیت اطلاعات کلیک نمایید.' },
    //     { shouldFocus: true }
    //   );
    // }

    const reqData = {
      [validation.fieldNames.message]: values[validation.fieldNames.message],
      // [validation.fieldNames.team]: caravanId,
      // ...(values[validation.fieldNames.clients]?.length && {
      //   [`${[validation.fieldNames.clients]}[]`]: values[validation.fieldNames.clients]?.map((x) => x?.value),
      // }),
    };

    // if (values[validation.fieldNames.clients]?.length)
    //   values[validation.fieldNames.clients]?.forEach(
    //     (x, i) => (reqData[`${[validation.fieldNames.clients]}[${i}]`] = x?.value)
    //   );
    const formData = new FormData();
    // if (values[validation.fieldNames.clients]?.length)
    //   values[validation.fieldNames.clients]?.forEach((x) =>
    //     formData.append([`${[validation.fieldNames.clients]}[]`], x?.value)
    //   );

    Object.keys(reqData).forEach((key) => {
      formData.append(key, reqData[key]);
    });

    console.log('* * * onSubmit : ', { reqData, values });

    mutate(formData);
  };
  console.log({ queryParams });

  return (
    <Box
      sx={{
        width: '100%',
        // display: { xs: 'block', md: 'flex' },
        gap: 2,
        bgcolor: 'background.paper',
        borderRadius: 1,
        p: 4,
      }}
    >
      <HeaderBreadcrumbs
        heading={
          paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
        back={backUrl}
        // links={[
        //   { name: 'داشبورد', href: Enum.routes.root(queryParams.type, queryParams.travel) },
        //   { name: title, href: Enum.routes.list },
        //   { name: 'لیست' },
        // ]}
      />
      {/* <HeaderBreadcrumbs
        back={`${Enum.routes.root}`}
        heading={
          paramId ? t('editAuthor', { author: Enum.title.name[0] }) : t('addAuthor', { author: Enum.title.name[0] })
        }
      >
      </HeaderBreadcrumbs> */}
      {/* <Box
        sx={{
          textAlign: 'center',
          mb: '30px',
        }}
        onClick={() => navigate(backUrl)}
      >
        <Typography variant="h5">{'شما در حال ایجاد یک دوره جدید هستید!'}</Typography>
      </Box> */}

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.message} label={'متن پیام'} />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <RHFTextareaField name={validation.fieldNames.send_to} label={'شماره های همراه'} />
            </Grid> */}
            {/* <Grid item xs={12} md={12}>
              <CaravanSelector.Element name={validation.fieldNames.team} label={'کاروان'} />
            </Grid>

            <Grid item xs={12} md={12}>
              <ProfileSelector.Element multiple name={validation.fieldNames.clients} label={'کاربران'} />
            </Grid> */}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {errors?.all ? <FormHelperText error>{errors?.all?.message}</FormHelperText> : ''}
        </Box>
        <Box
          sx={{
            display: 'flex',
            mt: 3,
            gap: 3,
            justifyContent: 'center',
          }}
        >
          {/* <LoadingButton
            fullWidth
            // type="click"
            variant="outlined"
            color="success"
            loading={isLoading}
            onClick={onClose}
          >
            {'لغو'}
          </LoadingButton> */}

          <LoadingButton type="submit" variant="contained" color={'success'} loading={isLoading}>
            {'ثبت اطلاعات'}
          </LoadingButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default Add;
