// import { fDateTime } from '../../../../utils/formatTime';

export const tableData = () => [
  {
    key: 'name',
    header: 'نام',
    Component: ({ param }) => param?.client?.name || '-',
  },
  {
    key: 'lastName',
    header: 'نام خانوادگی',
    Component: ({ param }) => param?.client?.last_name || '-',
  },
  {
    key: 'nationalCode',
    header: 'کدملی',
    Component: ({ param }) => param?.client?.code_melli || '-',
  },
  {
    key: 'phone',
    header: 'تلفن همراه',
    Component: ({ param }) => param?.client?.phone || '-',
  },
  {
    key: 'shenasname_number',
    header: 'شماره شناسنامه',
    Component: ({ param }) => param?.client?.shenasname_number || '-',
  },
  {
    key: 'id',
    header: 'کد شناسایی/ID',
    Component: ({ param }) => param?.client?.id || '-',
  },
];
