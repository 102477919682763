/* eslint-disable */
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material';
import { cloneElement, useState, useMemo } from 'react';

// import { withStyles } from '@mui/styles';
// import { styled } from '@mui/material/styles';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import TableHeadCellCustom from './TableHeadCellCustom';
import TableNoData from './TableNoData';
import TableSkeleton from './TableSkeleton';
import TableSelectedActions from './TableSelectedActions';
import useQueryString from '../../utils/useQueryString';
import WaitingBox from '../WaitingBox';
import TableMoreMenu from './TableMoreMenu';

const TableComponent = ({ options, data, active, tableSetting, pagination, loading, show }) => {
  const {
    dense,
    page,
    order,
    orderBy,
    limit,
    setPage,
    selected,
    selectedObject,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onSearch,
    search,
    queryString,
    setFilter,
  } = tableSetting;

  const [toggleFilter, setToggleFilter] = useState();
  const onToggleFilter = () => setToggleFilter((p) => !p);
  // const queryString = useQueryString();
  console.log({ tableSetting, active, options });

  let skeletonRowCount = options?.length || 0;
  if (active?.selectable) skeletonRowCount += 1;
  if (active?.rowNumber) skeletonRowCount += 1;

  // const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: 'white',
  //   },
  //   '&:nth-of-type(even)': {
  //     backgroundColor: 'grey',
  //   },
  // }));

  const filteredItems = useMemo(() => {
    const final = [];
    options?.forEach((prop) => {
      const val = queryString?.[prop?.key];
      console.log({ val });
      const lookup = prop?.filter?.lookup;
      if (val && lookup) {
        const find = (
          prop?.filter?.multiple && val?.length
            ? lookup?.filter((x) => val?.some((y) => y == x.value))
            : lookup?.filter((x) => x.value == val)
        )
          ?.map((y) => y?.label)
          ?.join(' , ');

        final.push({ key: prop.key, label: prop?.header, value: lookup ? find : val, lookup });
      }
    });
    return final;
  }, [queryString]);
  console.log({ filteredItems, queryString });
  return (
    <>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'start', my: 2, px: 2 }}>
          <Box display="flex" gap={1}>
            {active?.filter ? (
              <Button
                variant="contained"
                color="grey"
                sx={{
                  padding: '0 10px',
                  minWidth: 'auto',
                  border: 0,
                }}
                onClick={onToggleFilter}
              >
                <svg width={25} height={25}>
                  <use xlinkHref="#filter" />
                </svg>
              </Button>
            ) : (
              ''
            )}
            <TextField
              placeholder="جستجو ..."
              onChange={(e) => onSearch(e.target.value)}
              defaultValue={search}
              InputProps={{
                // className: ' !rounded-l-[50px] !border-gray-50',
                sx: {
                  borderRadius: '8px',
                },
                inputProps: {
                  // className: ' p-[10px]',
                  sx: {
                    padding: '10px',
                  },
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                ),
              }}
              // sx={{mr: 1, fontWeight: 'fontWeightBold'}}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            px: '20px',
            gap: 2,
          }}
        >
          {show?.filters || ''}

          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            {filteredItems?.map((x, i) => {
              return (
                <Chip
                  label={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Box> {`${x?.label}`}</Box>:<Box>{`${x?.value}`}</Box>
                    </Box>
                  }
                  onDelete={() => setFilter([x.key], undefined)}
                />
              );
            })}
          </Box>
        </Box>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative', mt: 1.5 }}>
            {/* {selected.length > 0 && (
            <TableSelectedActions
              dense={dense}
              numSelected={selected.length}
              rowCount={data?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  data.map((row) => row._id)
                )
              }
              actions={
                <Tooltip title="Delete">
                  <IconButton
                    color="primary"
                    //  onClick={() => handleDeleteRows(selected)}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                  </IconButton>
                </Tooltip>
              }
            />
          )} */}

            <Table size={dense ? 'small' : 'medium'}>
              <TableHead>
                <TableRow>
                  {active?.selectable ? <TableCell sx={{ width: 48 }}>{'انتخاب'}</TableCell> : ''}
                  {active?.rowNumber ? <TableCell sx={{ width: 48 }}>{'ردیف'}</TableCell> : ''}
                  {options?.map(({ key, ...props }) => (
                    <TableHeadCellCustom
                      key={`table-head-${key}`}
                      {...{ onSort, orderBy, order, queryString }}
                      filterValue={queryString?.[key]}
                      setFilter={setFilter}
                      {...props}
                      field={key}
                    />
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {!loading ? (
                  <>
                    {data?.map((d, i) => (
                      <TableRow
                        key={`table-body-row-${d?._id || d?.id}`}
                        hover
                        // selected={selected.includes(d?._id || d?.id)}
                        //   sx={{
                        //     borderBottom: `2px solid rgba(145, 158, 171, 0.08)`,
                        //   }}
                      >
                        {active?.selectable ? (
                          <TableCell>
                            {
                              <Checkbox
                                color="success"
                                checked={!!selectedObject?.[d?.id]}
                                onChange={(e) => {
                                  if (active?.selectable?.select) {
                                    active.selectable.select(d);
                                  } else {
                                    onSelectRow(d);
                                  }
                                }}
                              />
                            }
                          </TableCell>
                        ) : (
                          ''
                        )}
                        {active?.rowNumber ? <TableCell>{i + 1 + +(page - 1) * +limit}</TableCell> : ''}
                        {/* i + 1 +  */}
                        {options?.map(({ key, Component, cellProps }) => (
                          <TableCell key={`table-body-row-cell-${key}`} {...cellProps}>
                            {Component ? <Component param={d} /> : d[key]}
                          </TableCell>
                        ))}
                      </TableRow>
                      // <TableRowItems
                      //   key={row._id}
                      //   row={row}
                      //   selected={selected.includes(row._id)}
                      //   onSelectRow={() => onSelectRow(row._id)}
                      //   onDeleteRow={() => handleDeleteRow(row._id)}
                      //   onEditRow={() => handleEditRow(row._id)}
                      //   onShowPlans={() => handleShowPlan(row._id)}
                      //   onAddReportage={() => handleAddReportage(row._id)}
                      // />
                    ))}
                  </>
                ) : (
                  ''
                )}

                {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData?.data?.length)} /> */}
                {loading ? (
                  <TableSkeleton cols={10} rows={skeletonRowCount} />
                ) : (
                  <TableNoData isNotFound={!data?.length} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Box sx={{ position: 'relative' }}>
          {pagination?.totalPage > 1 ? (
            <TablePagination
              rowsPerPageOptions={false}
              component="div"
              count={pagination?.total || 0}
              rowsPerPage={limit}
              page={page - 1}
              onPageChange={(e, page) => onChangePage(e, page + 1)}
              onRowsPerPageChange={onChangeRowsPerPage}
              // translate='yes'
              labelDisplayedRows={({ from, to, count }) => (
                <Box
                  sx={{
                    direction: 'rtl !important',
                  }}
                >
                  {`${from} - ${to} to ${count}`}
                </Box>
              )}
            />
          ) : (
            ''
          )}
          {/* <Pagination
          onChange={(e) => console.log({ e: e.target.value })}
          page={page}
          count={pagination?.total}
          size="small"
        /> */}
          {/* <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={10}
          rowsPerPage={2}
          page={0}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          // translate='yes'
          labelDisplayedRows={({ from, to, count }) => (
            <Box
              sx={{
                direction: 'rtl !important',
              }}


            >
              {`${from} - ${to} to ${count}`}
            </Box>
          )}
        /> */}

          {/* <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            /> */}
        </Box>
      </Card>
      {active?.filter ? (
        <Modal
          open={!!toggleFilter}
          onClose={() => setToggleFilter(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* <div>{active?.filter?.component}</div> */}

          {active?.filter?.component ? (
            cloneElement(active?.filter?.component, {
              // Append new prop
              // ...active?.filter?.component?.props,
              onClose: () => setToggleFilter(false),
            })
          ) : (
            <> </>
          )}
        </Modal>
      ) : (
        ''
      )}
    </>
  );
};

export default TableComponent;
