import { Box } from '@mui/material';
import validation from '../validation';
import RHFRadioGroups from '../../../../../components/hook-form/RHFRadioGroups';
import { optionsLocation } from '../../../../Form/enum';

const FormTypeLocation = () => {
  return (
    <Box>
      <Box
        sx={{
          borderTop: '1px solid',
          borderColor: 'grey.200',
          mt: 1,
          mb: 3,
        }}
      />

      <RHFRadioGroups options={optionsLocation || []} name={validation.fieldNames.locationType} />
    </Box>
  );
};

export default FormTypeLocation;
