import { Route, Routes } from 'react-router';
import Enum from './enum';
import List from './List';
import Add from './Add';
import buildAccess from '../../permission/buildAccess';
import PermissionRoute from '../../permission/permissionRoute';

const UniversityCategory = () => {
  const access = buildAccess(Enum.routes.name);
  return (
    <Routes>
      <Route
        path={`${Enum.routes.add}`}
        element={<PermissionRoute element={<Add access={access} />} permissions={access?.create} />}
      />
      <Route
        path={`${Enum.routes.edit}/:id`}
        element={<PermissionRoute element={<Add access={access} />} permissions={access?.update} />}
      />
      <Route
        path={`${Enum.routes.list}`}
        element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />}
      />
      <Route path={`/`} element={<PermissionRoute element={<List access={access} />} permissions={access?.read} />} />
    </Routes>
  );
  // return (
  //   <Routes>
  //     <Route path={`${Enum.routes.add}`} element={<Add />} />
  //     <Route path={`${Enum.routes.edit}/:id`} element={<Add />} />
  //     <Route path={`${Enum.routes.list}`} element={<List />} />
  //     <Route path={`/`} element={<List />} />
  //   </Routes>
  // );
};

export default UniversityCategory;
