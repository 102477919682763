import { Box, Chip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { acceptFinallyStatus, documentStatus } from 'src/enumeration';
import EProfile from 'src/pages/profiles/enum';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fDateTime } from '../../../utils/formatTime';
import Enum from '../enum';

export const tableData = () => [
  // {
  //   key: 'name',
  //   header: 'نام',
  //   Component: ({ param }) => param?.client?.name || '-',
  // },
  // {
  //   key: 'lastName',
  //   header: 'نام خانوادگی',
  //   Component: ({ param }) => param?.client?.last_name || '-',
  // },
  {
    key: 'name',
    header: 'نام و نام خانوادگی',
    Component: ({ param }) =>
      param?.client?.name || param?.client?.last_name ? (
        <Link
          to={`${PATH_DASHBOARD.profile.root}${PATH_DASHBOARD.profile.show}/${param.client.id}/${EProfile.enumTab.object.travels.value}/${param.id}`}
        >
          <Box sx={{ color: 'grey.900' }}>{`${param?.client?.name || '-'} ${param?.client?.last_name || '-'} ${
            param?.members > 1 ? `(${param?.members})` : ''
          }`}</Box>
        </Link>
      ) : (
        '-'
      ),
  },
  {
    key: 'nationalCode',
    header: 'کدملی',
    Component: ({ param }) => param?.client?.code_melli || '-',
  },
  {
    key: 'phone',
    header: 'تلفن همراه',
    Component: ({ param }) => param?.client?.phone || '-',
  },
  {
    key: 'shenasname_number',
    header: 'شماره شناسنامه',
    Component: ({ param }) => param?.client?.shenasname_number || '-',
  },
  // {
  //   key: 'reason',
  //   header: 'تشرف به صورت',
  //   Component: ({ param }) => param?.travel?.name || '-',
  // },
  {
    key: 'identity_status',
    header: 'مدارک هویتی',
    filter: {
      lookup: Object.values(documentStatus),
    },
    Component: ({ param }) => (
      <Chip label={param?.identity_status} color={Enum.status.object[param?.identity_status_num]?.color} />
    ),
  },
  {
    key: 'bank_status',
    header: 'امور بانکی',
    filter: {
      lookup: Object.values(documentStatus),
    },
    Component: ({ param }) => (
      <Chip label={param?.bank_status} color={Enum.status.object[param?.bank_status_num]?.color} />
    ),
  },
  {
    key: 'duty_status',
    header: 'نظام وظیفه',
    filter: {
      lookup: Object.values(documentStatus),
    },
    Component: ({ param }) => (
      <Chip label={param?.duty_status} color={Enum.status.object[param?.duty_status_num]?.color} />
    ),
  },
  {
    key: 'status',
    header: 'تایید نهایی',
    filter: {
      lookup: Object.values(acceptFinallyStatus),
      multiple: true,
    },
    Component: ({ param }) => <Chip label={param?.status} color={Enum.status.object[param?.status_num]?.color} />,
  },
  {
    key: 'createdAt',
    header: 'تاریخ',
    filter: {
      sortable: true,
    },
    Component: ({ param }) => fDateTime(param?.client?.created_at) || '-',
  },
];
